import React, { useState, useEffect, useContext } from "react";
import { useLocation } from "react-router-dom";
import axios from "../../api/api.js"
import moment from "moment-timezone";
import { AuthContext } from "../../auth/AuthProvider.js";
import Swal from "sweetalert2";
import { Row, Col, Form, Table } from "react-bootstrap";
import "../../styles/app.css";
import { getCustomsDoc, getMasterItem, getMasterTrxOUT, getMasterVendor } from "../utils/utilsGetData.js";

// function component edit transaction IN
export function EditTrxOut(){
    const userLogin     = useContext(AuthContext); // use auth context
    
    // state definition
    const [listVendor, setListVendor]           = useState([]);
    const [listItem, setListItem]               = useState([]);
    const [listCustomsDoc, setListCustomsDoc]   = useState([]);
    const [listTransaction, setListTransaction] = useState([]);
    const [dataHeader, setDataHeader]           = useState({});
    const [dataDetail, setDataDetail]           = useState([]);
    const [newDataDetail, setNewDataDetail]     = useState({
        ItemID: "",
        ItemDescription:"",
        ItemHSCode:"",
        ItemColor:"",
        ItemSerial:"",
        UOM:"",
        Qty:0,
        Currency:"",
        UnitPrice:0,
        idLogTrx:0
    });
    
    
    function useQuery() {
        return new URLSearchParams(useLocation().search);
      }
    
    const query     = useQuery();
    const docno     = query.get('docno');
    var DocumentTitle;
    
    const fetchDocHeader = async (DocumentID) => {
        try {
            const response = await axios.get(`/api/outbound/header/${encodeURIComponent(DocumentID).trim()}`);
            setDataHeader(response.data.data[0]);
            setNewDataDetail(response.data.data[0]);
        } catch(error){ console.error('Error fetching data:', error)};
    };

    const fetchDocDetail = async(DocumentID) => {
        try{
            const response = await axios.get(`/api/outbound/detail/${encodeURIComponent(DocumentID).trim()}`);
            setDataDetail(response.data.data);
        } catch(error){ console.error('Error fetching data:', error)};
    }
    

    const ocDocHeader = (event) => {
        const { name, value } = event.target;
        setDataHeader(prevState => ({
            ...prevState,
            [name]: value,
            UpdateBy: userLogin.value.userName,
            UpdateDate: moment().format("YYYY-MM-DD hh:mm:ss")
        }));
        setNewDataDetail(prevState => ({
            ...prevState,
            [name]: value,
            UpdateBy: userLogin.value.userName,
            UpdateDate: moment().format("YYYY-MM-DD hh:mm:ss")
        }));
    };

    const ocDocDetail = (event) => {
        const { name, value } = event.target;
        setNewDataDetail(prevState => ({
            ...prevState,
            [name]: value,
            UpdateBy: userLogin.value.userName,
            UpdateDate: moment().format("YYYY-MM-DD hh:mm:ss")
        }));
    };
    
    
    const saveDocData = (event) => {
        try {
            event.preventDefault();
            const TrxType = listTransaction.find(trx => trx.trx_name === dataHeader.DocumentType);
            console.log(TrxType);
            var searchTerm;
            switch(TrxType.trx_type){
                case 'MATERIAL':
                    searchTerm = 'mat';
                    break;
                case 'EQUIPMENT':
                    searchTerm = 'eq';
                    break;
                case 'FINISH GOOD':
                    searchTerm = 'fg';
                    break;
                case 'WASTE':
                    searchTerm = 'was';
                    break;
                default:
                    searchTerm = 'mat';
                    break;
            }

            axios
            .post(`/api/outbound/${searchTerm}/detail/new`, { detailData: newDataDetail })
            .then((response) => { if (response.status === 200) { 
                fetchDocDetail(dataHeader.DocumentID);
                return showToastAddMaterial() 
            }})
            .catch((error) => { if (error.response) return console.log('fail') });
          } catch (error) {
            return console.log(error);
          }
    }

    const putDocHeader = (event, DocumentID) => {
        try {
            event.preventDefault();
            console.log(DocumentID);
            axios
                .post(`/api/outbound/header/${encodeURIComponent(DocumentID)}`, { headerData: dataHeader })
                .then((response) => { if (response.status === 200) return showToastSaveHeader() })
                .catch((error) => { if (error.response) return console.log('data header not saved') });
          } catch (error) {
            return console.log(error);
          }
    }

    const ocBtnDelItem = (event, DocumentID, ItemID, idLogTrx) => {
        try{
            event.preventDefault();
            axios
            .delete(`/api/outbound/${encodeURIComponent(DocumentID)}/${encodeURIComponent(ItemID)}/${idLogTrx}`)
            .then((response) => { if (response.status === 200) 
                fetchDocDetail(dataHeader.DocumentID);
                return showToastDelItem(); 
            })
            .catch((error) => { if (error.response) return console.log('data header not saved') });
        } catch(error){
            return console.log(error);
        }
        
    }

    useEffect(() => {  
        const fetchMasterData = async() => {
            try{
                const MasterCustomsDoc  = await getCustomsDoc();
                const MasterItem        = await getMasterItem();
                const MasterVendor      = await getMasterVendor();
                const MasterTrxOUT      = await getMasterTrxOUT();
                setListCustomsDoc(MasterCustomsDoc);
                setListItem(MasterItem);
                setListVendor(MasterVendor);
                setListTransaction(MasterTrxOUT);
            } catch(error){ console.error('Error fetching data master:', error) }
        }
        fetchMasterData();
        fetchDocHeader(docno);
        fetchDocDetail(docno);
    }, [docno]);
    
    const showToastDelItem = () => {
        Swal.fire({
          icon: 'success',
          title: 'Hapus Item berhasil!',
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
    }
    
    const showToastSaveHeader = () => {
        Swal.fire({
          icon: 'success',
          title: 'Update Header berhasil!',
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
    }

    const showToastAddMaterial = () => {
        Swal.fire({
          icon: 'success',
          title: 'Tambah Material berhasil!',
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
    }
    
    
    return(
        <div className="content-wrapper">
                <div className="content">
                    <div className="container-fluid px-2">
                        <Row>
                            <Col sm={12} lg={12}>
                                <br/>
                                <h5 className="m-0">EDIT TRANSAKSI DATA - {DocumentTitle} {docno} </h5>
                                <hr/>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={12} md={12} lg={12}>
                                <Form>
                                    <Row>
                                        <Col sm={12} md={3} lg={2} xl={2} className="px-1 py-0 form-group">
                                            <label htmlFor="inputCustomsDocType" className="form-label form-label font-xsmall">Tipe Dok BC</label>
                                            <select className="form-control form-control-sm font-xsmall" name="CustomsDocType font-xsmall" value={dataHeader.CustomsDocType} id="inputCustomsDocType" onChange={ocDocHeader} required>
                                                <option value={""}>  </option>
                                                {listCustomsDoc.map((item, index) => (
                                                    <option key={item.CustomsDocType} value={item.CustomsDocType}  >{item.CustomsDocType} </option> 
                                                ))}
                                            </select>
                                        </Col>
                                        <Col sm={12} md={3} lg={2} xl={2} className="px-1 py-0 form-group">
                                            <label htmlFor="inputCustomsDocID" className="form-label form-label font-xsmall">Nomor Dok BC</label>
                                            <input type="text" className="form-control form-control-sm  font-xsmall" name="CustomsDocID" value={dataHeader.CustomsDocID} id="inputCustomsDocID" onChange={ocDocHeader} ></input>
                                        </Col>
                                        <Col sm={12} md={3} lg={2} xl={2} className="px-1 py-0 form-group">
                                            <label htmlFor="inputCustomsDocDate" className="form-label form-label font-xsmall">Tanggal Dok BC</label>
                                            <input type="date" className="form-control form-control-sm  font-xsmall" name="CustomsDocDate" value={dataHeader.CustomsDocDate} id="inputCustomsDocDate"  onChange={ocDocHeader} ></input>
                                        </Col>
                                        <Col sm={12} md={3} lg={2} xl={2} className="px-1 py-0 form-group">
                                            <label htmlFor="inputDocumentID" className="form-label form-label font-xsmall">Nomor Dokumen LPB</label>
                                            <input type="text" className="form-control form-control-sm  font-xsmall" name="DocumentID" value={dataHeader.DocumentID} id="inputDocumentID" onChange={ocDocHeader} required disabled></input>
                                        </Col>
                                        <Col sm={12} md={3} lg={2} xl={2} className="px-1 py-0 form-group">
                                            <label htmlFor="inputDocumentDate" className="form-label form-label font-xsmall">Tanggal Dokumen</label>
                                            <input type="date" className="form-control form-control-sm  font-xsmall" name="DocumentDate" value={dataHeader.DocumentDate}  id="inputDocumentDate"  onChange={ocDocHeader} required></input>
                                        </Col>
                                        <Col sm={12} md={3} lg={2} xl={2} className="px-1 py-0 form-group">
                                            <label htmlFor="inputVendorID" className="form-label form-label font-xsmall">Penerima</label>
                                            <select className="form-control form-control-sm  font-xsmall" name="VendorID" id="inputVendorID" value={dataHeader.VendorID}  onChange={ocDocHeader} required>
                                                <option value={""}></option>
                                                {listVendor.map((item, index) => (
                                                    <option key={item.VendorID} value={item.VendorID} >{item.VendorID} - {item.VendorName} </option> 
                                                ))}
                                            </select>
                                        </Col>
                                        <Col sm={12} md={3} lg={2} xl={2} className="px-1 py-0 form-group">
                                            <label htmlFor="inputPackingSlipID" className="form-label form-label font-xsmall">Surat Jalan</label>
                                            <input type="text" className="form-control form-control-sm  font-xsmall" value={dataHeader.PackingSlipID} name="PackingSlipID"  onChange={ocDocHeader} id="inputPackingSlipID" ></input>
                                        </Col>
                                        <Col sm={12} md={3} lg={2} xl={2} className="px-1 py-0 form-group">
                                            <label htmlFor="inputTransportID" className="form-label form-label font-xsmall">Transport ID / Trucking</label>
                                            <input type="text" className="form-control form-control-sm  font-xsmall" value={dataHeader.TransportID} name="TransportID"  onChange={ocDocHeader} id="inputTransportID" ></input>
                                        </Col>
                                        <Col sm={12} md={3} lg={2} xl={2} className="px-1 py-0 form-group">
                                            <label htmlFor="inputInspectionDate" className="form-label form-label font-xsmall">Tanggal IQC</label>
                                            <input type="date" className="form-control form-control-sm font-xsmall" value={dataHeader.InspectionDate} name="InspectionDate"  onChange={ocDocHeader} id="inputInspectionDate" ></input>
                                        </Col>
                                        <Col sm={12} md={3} lg={2} xl={2} className="px-1 py-0 form-group">
                                            <label htmlFor="inputBLDocID" className="form-label form-label font-xsmall">Nomor BL</label>
                                            <input type="text" className="form-control form-control-sm font-xsmall" value={dataHeader.BLDocID} name="BLDocID" id="inputBLDocID" onChange={ocDocHeader}></input>
                                        </Col>
                                        <Col sm={12} md={3} lg={3} xl={2} className="px-1 py-0 form-group">
                                            <br/>
                                            <button className="btn btn-success " onClick={(event) => putDocHeader(event, dataHeader.DocumentID)} ><i className="right fas fa-save"></i></button>&nbsp;
                                        </Col>
                                    </Row>
                                </Form>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={12} md={12} lg={12}>
                                <hr/>
                                <p><strong>Tambah Detail Material</strong></p>                            
                            </Col>
                            <Col sm={12} md={12} lg={12}>
                                <form>
                                    <Row>
                                        <Col sm={12} md={4} lg={2} xl={2} className="px-1 form-group form-group-sm">
                                            <label htmlFor="inputPOID" className="form-label form-label font-xsmall">PO</label>
                                            <input type="text" className="form-control form-control-sm font-xsmall"  name="POID" id="inputPOID" value={newDataDetail.POID} onChange={ocDocDetail} ></input>
                                        </Col>
                                        <Col sm={12} md={4} lg={2} xl={2} className="px-1 form-group form-group-sm">
                                            <label htmlFor="inputMOID" className="form-label form-label font-xsmall">MO / JO</label>
                                            <input type="text" className="form-control form-control-sm font-xsmall"  name="MOID" id="inputMOID" value={newDataDetail.MOID} onChange={ocDocDetail} ></input>
                                        </Col>
                                        <Col sm={12} md={4} lg={2} xl={2} className="px-1 form-group form-group-sm">
                                            <label htmlFor="inputItemID" className="form-label form-label font-xsmall">Item ID</label>
                                            <input type="text" list="listItem" className="form-control form-control-sm font-xsmall" name="ItemID" id="inputItemID" value={newDataDetail.ItemID} onChange={ocDocDetail} required ></input>
                                            <datalist id="listItem">
                                                <option value={""}></option>
                                                {listItem.map((item, index) => (
                                                    <option key={item.ItemID} value={item.ItemID} {...newDataDetail.ItemID===item.ItemID?"selected":""}>{item.ItemID} - {item.ItemDescription}</option> 
                                                ))}
                                            </datalist>
                                        </Col>
                                        <Col sm={12} md={4} lg={2} xl={2} className="px-1 form-group form-group-sm">
                                            <label htmlFor="inputItemColor" className="form-label form-label font-xsmall">Item Color</label>
                                            <input type="text" className="form-control form-control-sm font-xsmall" id="inputItemColor" name="ItemColor" value={newDataDetail.ItemColor} onChange={ocDocDetail} required ></input>
                                        </Col>
                                        <Col sm={12} md={4} lg={2} xl={2} className="px-1 form-group form-group-sm">
                                            <label htmlFor="inputSerial" className="form-label form-label font-xsmall">Item Serial</label>
                                            <input type="text" className="form-control form-control-sm font-xsmall" id="inputSerial" name="ItemSerial" value={newDataDetail.ItemSerial} onChange={ocDocDetail} required ></input>
                                        </Col>
                                        <Col sm={12} md={4} lg={2} xl={2} className="px-1 form-group form-group-sm">
                                            <label htmlFor="inputVendorID" className="form-label form-label font-xsmall">HS Code</label>
                                            <input type="text" className="form-control form-control-sm font-xsmall" id="inputVendorID" name="ItemHSCode" value={newDataDetail.ItemHSCode} onChange={ocDocDetail} required ></input>
                                        </Col>
                                        <Col sm={12} md={4} lg={2} xl={2} className="px-1 form-group form-group-sm">
                                            <label htmlFor="inputUOM" className="form-label form-label font-xsmall">UOM</label>
                                            <input type="text" className="form-control form-control-sm font-xsmall" id="inputUOM" name="UOM" maxLength={"3"} value={newDataDetail.UOM} onChange={ocDocDetail} required  style={{textTransform:'uppercase'}}></input>
                                        </Col>
                                        <Col sm={12} md={4} lg={2} xl={2} className="px-1 form-group form-group-sm">
                                            <label htmlFor="inputQty" className="form-label form-label font-xsmall">Jumlah</label>
                                            <input type="number" step={'0.00'} className="form-control form-control-sm font-xsmall" name="Qty" id="inputQty" value={newDataDetail.Qty} onChange={ocDocDetail} required ></input>
                                        </Col>
                                        <Col sm={12} md={4} lg={2} xl={2} className="px-1 form-group form-group-sm">
                                            <label htmlFor="inputCurrency" className="form-label form-label font-xsmall">Mata Uang</label>
                                            <input type="text" className="form-control form-control-sm font-xsmall" id="inputCurrency" maxLength={"3"} name="Currency" value={newDataDetail.Currency} onChange={ocDocDetail} required  style={{textTransform:'uppercase'}}></input>
                                        </Col>
                                        <Col sm={12} md={4} lg={2} xl={2} className="px-1 form-group form-group-sm">
                                            <label htmlFor="inputUnitPrice" className="form-label form-label font-xsmall">Harga Satuan</label>
                                            <input type="number" step={"0.0000"} className="form-control form-control-sm font-xsmall" id="inputUnitPrice" name="UnitPrice" value={newDataDetail.UnitPrice} onChange={ocDocDetail} required></input>
                                        </Col>
                                        <Col sm={12} md={4} lg={2} xl={2} className="px-1 form-group">
                                            <br/>
                                            <button type="button" className="btn btn-success" onClick={saveDocData} ><i className="right fas fa-plus"></i></button>
                                        </Col>
                                    </Row>
                                </form>
                            </Col>
                            <Col sm={12} md={12} lg={12}>
                                <Table striped >
                                    <thead>
                                        <tr className="bg-primary shadow" style={{fontSize: "x-small"}}>
                                            <th>PO</th>
                                            <th>MO / JO</th>
                                            <th>Item ID</th>
                                            <th>Deskripsi</th>
                                            <th>Warna</th>
                                            <th>Serial</th>
                                            <th>HS Code</th>
                                            <th>Satuan</th>
                                            <th>Jumlah</th>
                                            <th>Mata Uang</th>
                                            <th>Harga Unit</th>
                                            <th>Nilai Barang</th>
                                            <th>Opsi</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {dataDetail.map((item, index) => (
                                        <tr key={index} style={{fontSize: "x-small"}}>
                                            <td>{item.POID}</td>
                                            <td>{item.MOID}</td>
                                            <td>{item.ItemID}</td>
                                            <td>{item.ItemDescription} {item.idLogTrx}</td>
                                            <td>{item.ItemColor}</td>
                                            <td>{item.ItemSerial}</td>
                                            <td>{item.ItemHSCode}</td>
                                            <td>{item.UOM}</td>
                                            <td>{item.Qty}</td>
                                            <td>{item.Currency}</td>
                                            <td>{item.UnitPrice}</td>
                                            <td>{item.Qty * item.UnitPrice}</td>
                                            <td><button type="button" className="btn btn-danger btn-sm" onClick={(event) => ocBtnDelItem(event, item.DocumentID, item.ItemID, item.idLogTrx)} ><i className="right fas fa-trash"></i></button></td>
                                        </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>
                    </div>
            </div>
        </div>
    )
}