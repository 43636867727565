import React, { useState, createContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import axios from "../api/api.js";

export const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const navigate = useNavigate();
  const [activeUserName, setActiveUserName] = useState("");
  const [activeUserFullName, setActiveUserFullName] = useState("");
  const [activeUserDept, setActiveUserDept] = useState("");
  const [activeId, setActiveId] = useState("");
  const [token, setToken] = useState("");
  const [expire, setExpire] = useState("");
  const [menus, setMenus] = useState([]);

  
  useEffect(() => {
    const refreshToken = async () => {
      return await axios
        .get(`/api/auth/token`)
        .then((response) => {
          setToken(response.data.accessToken);
          const decoded = jwtDecode(response.data.accessToken);
          setActiveId(decoded.userId);
          setActiveUserName(decoded.userName);
          setActiveUserFullName(decoded.userFullName);
          setActiveUserDept(decoded.userDept);
          setExpire(decoded.exp);
          axios
            .get(`/api/user-access/${decoded.userId}`)
            .then((response) => { setMenus(response.data.data)});
        })
        .catch((error) => {
          if (error.response) return navigate("/");
        });
    };
    refreshToken();
  }, [navigate]);

  const value = {
    token: token,
    userId: activeId,
    userName: activeUserName,
    userFullName: activeUserFullName,
    userDept: activeUserDept,
    expire: expire,
    menus: menus
  };

  return (
    <AuthContext.Provider value={{ value}}>
      {children}
    </AuthContext.Provider>
  );
};
