import axios from "../../api/api.js";

export const formatArrHeaderDoc = {
    CustomsDocType: '',
    CustomsDocID: '', 
    CustomsDocDate: '', 
    DocumentID: '', 
    DocumentDate: '', 
    VendorName: '', 
    DocumentType: '', 
    Origin:'', 
    CreateBy: '', 
    CreateDate:'', 
    UpdateBy:'', 
    UpdateDate:'' 
};

export async function getMasterVendor(){
    const getVendorID = await axios.get("/api/data/vendor");
    return getVendorID.data.data;
}

export async function getDataUser(){
    const getUserID = await axios.get("/api/users");
    return getUserID.data.data;
};

export async function getMasterTrxIN(){
    const getTrxIN = await axios.get('/api/data/transaction/all/in');
    return getTrxIN.data.data;
}

export async function getMasterTrxOUT(){
    const getTrxOUT = await axios.get('/api/data/transaction/all/out');
    return getTrxOUT.data.data;
}


export async function getMasterItem(){
    const getItem = await axios.get(`/api/data/item/all`);
    return getItem.data.data;
};


export async function getListItemCategory(ItemTypeCode){
    const itemType          = ItemTypeCode || 'MATERIAL';
    const getItemCategory   = await axios.get(`/api/data/item-category/${itemType}`);
    return getItemCategory.data.data;
};

export async function getCustomsDoc(){
    const getCustomsDoc     = await axios.get("/api/data/customsdoc");
    return getCustomsDoc.data.data;
}


export const removeObjectsWithNullValues = (array) => {
    return array.filter(obj => {
        return !Object.values(obj).includes(null);
    });
};


// Function to filter the object
export const filterObject = obj => {
    const filteredObj = {};
    for (const key in obj) {
      if (obj[key] !== null) {
        filteredObj[key] = obj[key];
      }
    }
    return filteredObj;
  };