import React, { useEffect, useState, useRef }  from "react";
import axios from "../../api/api.js"
import { Row, Col } from "react-bootstrap";
import moment from "moment-timezone";
import Swal from "sweetalert2";
import { Button } from "react-bootstrap";
import { AgGridReact } from "ag-grid-react";
import { defaultColDef, colDefTraceGRN, colDefTraceOrder, colDefStockCard, colDefFGMonitoring } from "../utils/utilsAgGrid.js";
import { renewPinStockCard, renewPinTrace } from "../utils/utilsCalculate.js";
import { columnFGMonitoring, columnListTraceGRN, columnListTraceOrder, columnStockCard, generateExcel, generateExcelTrace } from "../utils/utilsExportXLS.js";
import FileSaver from "file-saver";
import CompDateRangeWeek from "../utils/utilsDateSelector.js";
import "ag-grid-community/styles/ag-grid.css"; // Core grid CSS, always needed
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional theme CSS
import '../../App.css';
import "../../styles/app.css";
import { getMasterItem } from "../utils/utilsGetData.js";


export function ReportTraceBB(){
    const [dataTraceBB, setDataTraceBB]             = useState([]);
    const [dataTraceBBOrder, setDataTraceBBOrder]   = useState([]);
    const [dataStockCard, setDataStockCard]         = useState([]);
    const [dataFGMonitor, setDataFGMonitor]         = useState([]);
    const [listItem, setListItem]                   = useState([]);
    const [searchStartDate, setSearchStartDate]     = useState(moment().subtract(7,'d').format('YYYY-MM-DD'));
    const [searchEndDate, setSearchEndDate]         = useState(moment().format('YYYY-MM-DD'));
    const [dateSelection, setDateSelection]         = useState({ start: moment(), end: moment()});
    const [activeTab, setActiveTab]                 = useState('tab1');
    const [columnDefsGRN]                           = useState(colDefTraceGRN);
    const [columnDefsOrder]                         = useState(colDefTraceOrder);
    const [StCrdItemID, setStCrdItemID]             = useState('');
    const gridRef                                   = useRef();


    const handleTabClick = (tab) => { setActiveTab(tab)};


    const fetchDataTraceGRN = async(FilterStartDate, FilterEndDate) => {
        try{
            Swal.fire({
                title: "Please wait..",
                didOpen: async () => {
                  Swal.showLoading();
                  await axios
                    .get(`/api/trace-bb/${FilterStartDate}/${FilterEndDate}`)
                    .then((response) => { if (response.status === 200) return setDataTraceBB(response.data.data)})
                    .catch((error) => { if (error.response) return console.log("Error get Data")});
                    Swal.close();
            }});
        } catch(err){
            return console.log(err);
        }
    }

    const fetchDataTraceBLK = async(FilterStartDate, FilterEndDate) => {
        try{
            Swal.fire({
                title: "Please wait..",
                didOpen: async () => {
                  Swal.showLoading();
                  await axios
                    .get(`/api/trace-bb-order/${FilterStartDate}/${FilterEndDate}`)
                    .then((response) => { if (response.status === 200) { return setDataTraceBBOrder(response.data.data)}})
                    .catch((error) => { if (error.response) return console.log("Error get Data")});
                    Swal.close();
            }});
        } catch(err){
            return console.log(err);
        }
    }

    async function fetchDataStockCard(ItemID){
        try{
            Swal.fire({
                title: "Please wait..",
                didOpen: async () => {
                  Swal.showLoading();
                  await axios
                    .get(`/api/log/stock-card/${ItemID}`)
                    .then((response) => { if (response.status === 200) { return setDataStockCard(response.data.data)}})
                    .catch((error) => { if (error.response) return console.log("Error get Data")});
                    Swal.close();
            }});
        } catch(err){
            return console.log(err);
        }
    }

    async function fetchDataFGMonitor(FilterStartDate, FilterEndDate){
        try{
            Swal.fire({
                title: "Please wait..",
                didOpen: async () => {
                  Swal.showLoading();
                  await axios
                    .get(`/api/log/fg-monitoring/${FilterStartDate}/${FilterEndDate}`)
                    .then((response) => { if (response.status === 200) { return setDataFGMonitor(response.data.data)}})
                    .catch((error) => { if (error.response) return console.log("Error get Data")});
                    Swal.close();
            }});
        } catch(err){
            return console.log(err);
        }
    }

    async function handleChangeDateTraceGRN(start, end) {
        try {
            setDateSelection({ start, end });
            setSearchStartDate(start.format("YYYY-MM-DD"));
            setSearchEndDate(end.format("YYYY-MM-DD"));
            fetchDataTraceGRN(start.format("YYYY-MM-DD"), end.format("YYYY-MM-DD"));
        } catch (error) {
            console.error("Error in handleChangeDate:", error);
        }
    }

    async function handleChangeDateTraceBLK(start, end) {
        try {
            setDateSelection({ start, end });
            setSearchStartDate(start.format("YYYY-MM-DD"));
            setSearchEndDate(end.format("YYYY-MM-DD"));
            fetchDataTraceBLK(start.format("YYYY-MM-DD"), end.format("YYYY-MM-DD"));
        } catch (error) {
            console.error("Error in handleChangeDate:", error);
        }
    }

    const handleOptStockCard = (event) => {
        try {
            setStCrdItemID(event.target.value);
        } catch(err){
            return console.log(err);
        }
    }

    async function handleChangeDateFGMonitor(start, end) {
        try {
            setDateSelection({ start, end });
            setSearchStartDate(start.format("YYYY-MM-DD"));
            setSearchEndDate(end.format("YYYY-MM-DD"));
            fetchDataFGMonitor(start.format("YYYY-MM-DD"), end.format("YYYY-MM-DD"));
        } catch (error) {
            console.error("Error in handleChangeDate:", error);
        }
    }


    const clickViewStockCard = (event) => {
        try{
            fetchDataStockCard(StCrdItemID);
        } catch(err){
            return console.log(err);
        }
    }

    const gridOptions = {
        getRowStyle: params => {
            if (params.data.SumVarPercent >= 100) {
                return { background: '#b9f6ca ' };
            } else {
                return { background: '#ffebee ' };
            }
        }
    };
      
      
    const [pinnedBottomRowData, setPinnedBottomRowData] = useState([
        renewPinTrace(dataTraceBB),
    ]);

    const [pinBtnRowStockCard, setPinBtnRowStockCard] = useState([
        renewPinStockCard(dataStockCard),
    ]);
    
    function refreshPinned(gridRef, dataResultScan) {
        if (gridRef.current.api){
            setPinnedBottomRowData([renewPinTrace(dataResultScan)]);
            setPinBtnRowStockCard([renewPinStockCard(dataResultScan)])
        } 
    }
  
    useEffect(() => {
        const fetchMasterData = async() => {
            try{
                const MasterItem        = await getMasterItem();
                setListItem(MasterItem);
            } catch(error){ console.error('Error fetching data master:', error) }
        }
        fetchMasterData();
        if (gridRef.current.api){
            setPinnedBottomRowData([renewPinTrace(dataTraceBB)]);
            setPinBtnRowStockCard([renewPinStockCard(dataStockCard)])
        }
    }, [gridRef, dataTraceBB, dataStockCard]);

    function onfiltered(e) {
        const isFilterActive = e.api.isAnyFilterPresent(); 
        if (isFilterActive) {
            let dataRender = [];
            e.api.forEachNodeAfterFilter(function (node) {
                dataRender.push(node.data);
            });
            return refreshPinned(gridRef, dataRender);
        } else {
            return refreshPinned(gridRef, dataTraceBB);
        }
    }
  
    
    const handleButtonDownloadXLS = async (event) => {
        event.preventDefault();
        const sheetName1 = "Traceability Material GRN";
        const sheetName2 = "Traceability Material Order Produksi";
        try{
            const buffer = await generateExcelTrace(sheetName1, sheetName2, columnListTraceGRN, columnListTraceOrder, dataTraceBB, dataTraceBBOrder);
            const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            FileSaver.saveAs(blob, `LAPORAN-TRACEABILITY-MATERIAL-${moment(searchStartDate).format('YYYYMMDD')}-${moment(searchEndDate).format('YYYYMMDD')}.xlsx`);
        } catch(error){
            console.log(error);
        }
    }

    const handleButtonDownloadStockCard = async(event) => {
        event.preventDefault();
        const sheetName = "Kartu Stock";
        try{
            const buffer = await generateExcel(sheetName, columnStockCard, dataStockCard);
            const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            FileSaver.saveAs(blob, `KARTUSTOCK-${StCrdItemID}.xlsx`);
        } catch(error){
            console.log(error);
        }
    }

    const handleButtonDownloadFGMonitor = async(event) => {
        event.preventDefault();
        const sheetName = "Monitoring FG";
        try{
            const buffer = await generateExcel(sheetName, columnFGMonitoring, dataFGMonitor);
            const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            FileSaver.saveAs(blob, `FG-MONITORING.xlsx`);
        } catch(error){
            console.log(error);
        }
    }

    
    return(
        <div className="content-wrapper">
                <div className="content">
                    <div className="container-fluid px-2">
                        <Row>
                            <Col sm={12} lg={12}>
                                <br/>
                                <h5 className="m-0">Laporan Traceability Material</h5>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="pt-2 pt-lg-2">
                                <ul className="nav nav-tabs">
                                    <li className="nav-item">
                                        <button className={`nav-link ${activeTab === 'tab1' ? 'active' : ''}`} onClick={() => handleTabClick('tab1')}>Dokumen Pemasukan</button>
                                    </li>
                                    <li className="nav-item">
                                        <button className={`nav-link ${activeTab === 'tab2' ? 'active' : ''}`} onClick={() => handleTabClick('tab2')}>Order Produksi</button>
                                    </li>
                                    <li className="nav-item">
                                        <button className={`nav-link ${activeTab === 'tab3' ? 'active' : ''}`} onClick={() => handleTabClick('tab3')}>Kartu Stok</button>
                                    </li>
                                    <li className="nav-item">
                                        <button className={`nav-link ${activeTab === 'tab4' ? 'active' : ''}`} onClick={() => handleTabClick('tab4')}>Monitoring Finish Good</button>
                                    </li>
                                </ul>
                                <div className="tab-content">
                                    <div className={`tab-pane ${activeTab === 'tab1' ? 'active' : ''}`}>
                                        <Col sm={12} md={12} lg={12} className="pt-2 pt-lg-0">
                                            <Row>
                                                <Col lg={3} md={5} sm={8} className="form-group mb-2 mb-lg-0">
                                                    <label htmlFor="srcStartDate" className="form-label" style={{fontSize:"xx-small"}}>Pilih Tanggal: &nbsp;</label>
                                                    <CompDateRangeWeek
                                                        state={dateSelection}
                                                        handleCallback={handleChangeDateTraceGRN}
                                                        style={{width: "100%"}}
                                                    /> 
                                                </Col>
                                                <Col lg={9} md={7} sm={0} className="form-group mb-2 mb-lg-0">
                                                    <br/>
                                                    <Button className="btn btn-small btn-success swalDefaultAccess" onClick={handleButtonDownloadXLS} ><i className="right fas fa-download"></i></Button>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col sm={12} md={12} lg={12} className="pt-2 pt-lg-0">
                                            <br/>
                                            <div className="ag-theme-quartz text-center" style={{ height: "80vh", width: "100%", fontSize: "x-small" }} >
                                                <AgGridReact 
                                                    ref={gridRef}
                                                    onFilterChanged={(e) => onfiltered(e)}
                                                    columnDefs={columnDefsGRN} 
                                                    rowData={dataTraceBB || []} 
                                                    defaultColDef={defaultColDef} 
                                                    pagination={'true'} 
                                                    gridOptions={gridOptions}
                                                    pinnedBottomRowData={pinnedBottomRowData}>
                                                </AgGridReact>
                                            </div>
                                        </Col>
                                    </div>
                                    <div className={`tab-pane ${activeTab === 'tab2' ? 'active' : ''}`}>
                                        <Col sm={12} md={12} lg={12} className="pt-2 pt-lg-0">
                                            <Row>
                                                <Col lg={3} md={5} sm={8} className="form-group mb-2 mb-lg-0">
                                                    <label htmlFor="srcStartDate" className="form-label" style={{fontSize:"xx-small"}}>Pilih Tanggal: &nbsp;</label>
                                                    <CompDateRangeWeek
                                                        state={dateSelection}
                                                        handleCallback={handleChangeDateTraceBLK}
                                                        style={{width: "100%"}}
                                                    /> 
                                                </Col>
                                                <Col lg={9} md={7} sm={0} className="form-group mb-2 mb-lg-0">
                                                    <br/>
                                                    <Button className="btn btn-small btn-success swalDefaultAccess" onClick={handleButtonDownloadXLS} ><i className="right fas fa-download"></i></Button>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col sm={12} md={12} lg={12} className="pt-2 pt-lg-0">
                                            <br/>
                                            <div className="ag-theme-quartz text-center" style={{ height: "80vh", width: "100%", fontSize: "x-small" }} >
                                                <AgGridReact 
                                                    ref={gridRef}
                                                    onFilterChanged={(e) => onfiltered(e)}
                                                    columnDefs={columnDefsOrder} 
                                                    rowData={dataTraceBBOrder || []} 
                                                    defaultColDef={defaultColDef} 
                                                    pagination={'true'}
                                                    gridOptions={gridOptions}
                                                    pinnedBottomRowData={pinnedBottomRowData}>
                                                </AgGridReact>
                                            </div>
                                        </Col>
                                    </div>
                                    <div className={`tab-pane ${activeTab === 'tab3' ? 'active' : ''}`}>
                                        <Col sm={12} md={12} lg={12} className="pt-2 pt-lg-0">
                                            <Row>
                                                <Col lg={3} md={5} sm={8} className="form-group mb-2 mb-lg-0">
                                                    <label htmlFor="inputItemID" className="form-label form-label font-xsmall">Item ID</label>
                                                    <input type="text" list="listItem" className="form-control form-control-sm font-xsmall" name="ItemID" id="inputItemID" onChange={handleOptStockCard} required></input>
                                                    <datalist id="listItem">
                                                        <option value={""}></option>
                                                        {listItem.map((item, index) => (
                                                            <option key={item.ItemID} value={item.ItemID}>{item.ItemID} - {item.ItemDescription}</option> 
                                                        ))}
                                                    </datalist>
                                                </Col>
                                                <Col lg={9} md={7} sm={0} className="form-group mb-2 mb-lg-0">
                                                    <br/>
                                                    <Button className="btn btn-small btn-primary swalDefaultAccess" onClick={clickViewStockCard}><i className="right fas fa-search"></i></Button> &nbsp;
                                                    <Button className="btn btn-small btn-success swalDefaultAccess" onClick={handleButtonDownloadStockCard} ><i className="right fas fa-download"></i></Button>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col sm={12} md={12} lg={12} className="pt-2 pt-lg-0">
                                            <br/>
                                            <div className="ag-theme-quartz text-center" style={{ height: "80vh", width: "100%", fontSize: "x-small" }} >
                                                <AgGridReact 
                                                    ref={gridRef}
                                                    //onFilterChanged={(e) => onfiltered(e)}
                                                    columnDefs={colDefStockCard} 
                                                    rowData={dataStockCard || []} 
                                                    defaultColDef={defaultColDef} 
                                                    pagination={'true'}
                                                    pinnedBottomRowData={pinBtnRowStockCard}>
                                                </AgGridReact>
                                            </div>
                                        </Col>
                                    </div>
                                    <div className={`tab-pane ${activeTab === 'tab4' ? 'active' : ''}`}>
                                        <Col sm={12} md={12} lg={12} className="pt-2 pt-lg-0">
                                            <Row>
                                                <Col lg={3} md={5} sm={8} className="form-group mb-2 mb-lg-0">
                                                    <label htmlFor="srcStartDate" className="form-label" style={{fontSize:"xx-small"}}>Pilih Tanggal: &nbsp;</label>
                                                    <CompDateRangeWeek
                                                        state={dateSelection}
                                                        handleCallback={handleChangeDateFGMonitor}
                                                        style={{width: "100%"}}
                                                    /> 
                                                </Col>
                                                <Col lg={9} md={7} sm={0} className="form-group mb-2 mb-lg-0">
                                                    <br/>
                                                    <Button className="btn btn-small btn-success swalDefaultAccess" onClick={handleButtonDownloadFGMonitor} ><i className="right fas fa-download"></i></Button>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col sm={12} md={12} lg={12} className="pt-2 pt-lg-0">
                                            <br/>
                                            <div className="ag-theme-quartz text-center" style={{ height: "80vh", width: "100%", fontSize: "x-small" }} >
                                                <AgGridReact 
                                                    ref={gridRef}
                                                    columnDefs={colDefFGMonitoring} 
                                                    rowData={dataFGMonitor || []} 
                                                    defaultColDef={defaultColDef} 
                                                    pagination={'true'} 
                                                    //gridOptions={gridOptions}
                                                    pinnedBottomRowData={pinnedBottomRowData}>
                                                </AgGridReact>
                                            </div>
                                        </Col>
                                    </div>
                                </div>
                                <br/>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
    )
}