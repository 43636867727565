import React, { useContext } from "react";
import {
  useNavigate,
  Outlet,
  Link
} from "react-router-dom";
import axios from "../api/api.js";
import { AuthContext } from "../auth/AuthProvider.js";

function MainLayout(){
  const navigate  = useNavigate();
  const { value } = useContext(AuthContext);
  const menus     = value.menus;

  const logout = async () => {
    await axios
      .delete("/api/auth/logout")
      .then(() => {
        navigate("/");
      })
      .catch((error) => {
        console.log(error.message, 5000, "danger");
      });
  };

  return(
        <div className="wrapper" style={{ height: "100vh", width: "100%" }}>
          <nav className="main-header navbar navbar-expand navbar-primary shadow">
            <ul className="navbar-nav">
              <li className="nav-item">
                <a className="nav-link" data-widget="pushmenu" href="/" role="button"><i className="fas fa-bars text-white"></i> <span className="text-white small"><b>&nbsp; SUMBIRI IT INVENTORY</b></span></a>
              </li>
            </ul>
            <ul className="navbar-nav ml-auto">
              <li className="nav-item">
                <button className="btn nav-link" data-widget="fullscreen"><i className="fas fa-expand-arrows-alt text-white"></i></button>
              </li>
            </ul>
          </nav>
          <aside className="main-sidebar sidebar-light-primary elevation-4 sticky-top" style={{ position: "fixed", left: 0, right: 0, height: "100%" }}
>
            <Link to="/app/dashboard" className="brand-link text-center center">
               &nbsp;<img src="/images/logos.png" alt="Sumbiri Logo" className="brand-image center-block" style={{opacity: ".8"}} /><img src="/images/beacukai.png" alt="Sumbiri Logo" className="brand-image center-block" style={{opacity: ".8"}} />
              <span className="brand-text font-weight-light">&nbsp;</span>
            </Link>
            <div className="sidebar">
              <nav className="mt-2">
                <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
                {menus.filter(menu => menu.menuButton==='Y').map((menu, index) => (
                    <li className="nav-item" key={index}>
                      <Link to={menu.menuPath} className="nav-link active bg-primary">
                        <p className="text-white small"><small><b><i className={ `right fas ${menu.menuIcon}`}></i> {menu.menuTitle}</b></small></p>
                      </Link>
                    </li>                              
                  ))}
                  <li className="nav-item">  
                    <Link onClick={logout} className="nav-link active bg-primary">
                      <p className="text-white small"><small><b>Logout</b></small></p>
                    </Link>
                  </li>  
                  
                </ul>
              </nav>
            </div>
          </aside>

    <Outlet />
{/* 
      <footer className="main-footer py-0 my-0 text-center"  style={{ position: "sticky", zIndex: "100", bottom: 0, height: "10px" }}>
        <strong><small>Copyright &copy; 2024 PT Sumber Bintang Rejeki Semarang</small></strong>
      </footer> */}
  </div>

    )
}

export default MainLayout;