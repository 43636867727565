import ExcelJS from "exceljs";

export const columnListInOut    = [
  { header: 'Tipe Dokumen BC', key: 'CustomsDocType', width: 20 },
  { header: 'Nomor Dokumen BC', key: 'CustomsDocID', width: 10 },
  { header: 'Tanggal Dokumen BC', key: 'CustomsDocDate', width: 20 },
  { header: 'Nomor Dokumen', key: 'DocumentID', width: 20 },
  { header: 'Tanggal Dokumen', key: 'DocumentDate', width: 20 },
  { header: 'Supplier / Vendor', key: 'VendorName', width: 50 },
  { header: 'Kode Barang', key: 'ItemID', width: 20 },
  { header: "Nama Barang", key: "ItemDescription", width: 50},
  { header: "Satuan", key: "UOM" },
  { header: "Jumlah", key: "Qty", style: { numFmt: '0.00' } },
  { header: "Mata Uang", key: "Currency" },
  { header: "Nilai", key: "Value", style: { numFmt: '0.0000' } },
];

export const columnListInReport    = [
  { header: 'Tipe Dokumen BC', key: 'CustomsDocType', width: 20 },
  { header: 'Nomor Dokumen BC', key: 'CustomsDocID', width: 10 },
  { header: 'Tanggal Dokumen BC', key: 'CustomsDocDate', width: 20 },
  { header: 'Nomor Dokumen', key: 'DocumentID', width: 20 },
  { header: 'Tanggal Dokumen', key: 'DocumentDate', width: 20 },
  { header: 'Pengirim Barang', key: 'VendorName', width: 50 },
  { header: 'Kode Barang', key: 'ItemID', width: 20 },
  { header: "Nama Barang", key: "ItemDescription", width: 50},
  { header: "Satuan", key: "UOM" },
  { header: "Jumlah", key: "Qty", style: { numFmt: '0.00' } },
  { header: "Mata Uang", key: "Currency" },
  { header: "Nilai", key: "Value", style: { numFmt: '0.0000' } },
];

export const columnListOutReport    = [
  { header: 'Tipe Dokumen BC', key: 'CustomsDocType', width: 20 },
  { header: 'Nomor Dokumen BC', key: 'CustomsDocID', width: 10 },
  { header: 'Tanggal Dokumen BC', key: 'CustomsDocDate', width: 20 },
  { header: 'Nomor Dokumen', key: 'DocumentID', width: 20 },
  { header: 'Tanggal Dokumen', key: 'DocumentDate', width: 20 },
  { header: 'Penerima Barang', key: 'VendorName', width: 50 },
  { header: 'Kode Barang', key: 'ItemID', width: 20 },
  { header: "Nama Barang", key: "ItemDescription", width: 50},
  { header: "Satuan", key: "UOM" },
  { header: "Jumlah", key: "Qty", style: { numFmt: '0.00' } },
  { header: "Mata Uang", key: "Currency" },
  { header: "Nilai", key: "Value", style: { numFmt: '0.0000' } },
];


export const columnListSummary    = [
  { header: 'Tipe Dokumen BC', key: 'CustomsDocType', width: 20 },
  { header: 'Nomor Dokumen BC', key: 'CustomsDocID', width: 10 },
  { header: 'Tanggal Dokumen BC', key: 'CustomsDocDate', width: 20 },
  { header: 'Nomor Dokumen', key: 'DocumentID', width: 20 },
  { header: 'Tanggal Dokumen', key: 'DocumentDate', width: 20 },
  { header: 'Supplier / Vendor', key: 'VendorName', width: 50 },
  { header: 'Kode Barang', key: 'ItemID', width: 20 },
  { header: "Nama Barang", key: "ItemDescription", width: 50},
  { header: "Warna Barang", key: "ItemColor", width: 50},
  { header: "Serial Barang", key: "ItemSerial", width: 50},
  { header: "Satuan", key: "UOM" },
  { header: "Jumlah", key: "Qty", style: { numFmt: '0.00' } },
  { header: "Mata Uang", key: "Currency" },
  { header: "Nilai", key: "Value", style: { numFmt: '0.0000' } },
  { header: "Remark", key: "Remark" },
  
];


export const columnListWIP = [
  { header: "Kode Barang", key: "ItemID" },
  { header: "Nama Barang", key: "ItemDescription"  },
  { header: "Satuan", key: "UOM"},
  { header: "Saldo Akhir", key: "SaldoWIPAkhir", style: { numFmt: '0.00' } }
];


export const columnLogProd = [
  { header: "No Dokumen", key: "DocumentID"},
  { header: "Tanggal Dokumen", key: "DocumentDate"},
  { header: "Customer", key: "CustomerName"},
  { header: "Order PO", key: "OrderReferencePONo"},
  { header: "Manufacture Order", key: "MO"},
  { header: "Item ID", key: "ItemID"},
  { header: "Item Description", key: "ItemDescription"},
  { header: "UOM", key: "UOM"},
  { header: "Qty", key: "Qty", style: { numFmt: '0.00' }},
  { header: "Create By", key: "CreateBy"},
  { header: "Create Date", key: "CreateDate"},
];


export const columnListMutasi = [
  { header: "Kode Barang", key: "ItemID", width: 20 },
  { header: "Nama Barang", key: "ItemDescription", width: 50 },
  { header: "Satuan", key: "UOM", width: 20 },
  { header: "Saldo Awal", key: "SaldoAwal", style: { numFmt: '0.00' } },
  { header: "Pemasukan", key: "SaldoMasuk", style: { numFmt: '0.00' } },
  { header: "Pengeluaran", key: "SaldoKeluar", style: { numFmt: '0.00' } },
  { header: "Penyesuaian", key: "SaldoAdjustment", style: { numFmt: '0.00'}},
  { header: "Saldo Buku", key: "SaldoAkhir", style: { numFmt: '0.00' } },
  { header: "Stock Opname", key: "SaldoStockOpname", style: { numFmt: '0.00'}},
  { header: "Ket", key: "Keterangan"}
];

export const columnListTraceGRN = [
  { header: "Tipe Dokumen BC", key: "CustomDocTypeCode"},
  { header: "Nomor Dokumen BC", key: "CustomDocNo"},
  { header: "Tanggal Dokumen BC", key: "CustomDocDate"},
  { header: "GRN ID", key: "GRNID" },
  { header: "InhouseDate", key: "InhouseDate" },
  { header: "Item ID", key: "ItemID" },
  { header: "Item Description", key: "ItemDescription" },
  { header: "UOM", key: "UOM" },
  { header: "Total GRN", key: "GRNQty", style: { numFmt: '0.00' } },
  { header: "Total GIN", key: "GINQty", style: { numFmt: '0.00' } },
  { header: "Total MRV", key: "MRVQty", style: { numFmt: '0.00' } },
  { header: "Total MRR", key: "MRRQty", style: { numFmt: '0.00' } },
  { header: "Total MRS", key: "MRSQty", style: { numFmt: '0.00' } },
  { header: "Variance Qty", key: "SumVarQty", style: { numFmt: '0.00' } },
  { header: "Percentage %", key: "SumVarPercent", style: { numFmt: '0.00%' } },
];

export const columnListTraceOrder = 
[
  { header: "Order ID", key: "OrderID" },
  { header: "Item ID", key: "ItemID" },
  { header: "Item Description", key: "ItemDescription" },
  { header: "UOM", key: "UOM"},
  { header: "BOM Required Qty", key: "BOMRequiredQty", style: { numFmt: '0.00' } },
  { header: "BOM Approved Purchase", key: "BOMApprovedPO", style: { numFmt: '0.00' } },
  { header: "BOM Allocated Qty", key: "BOMAllocatedQty", style: { numFmt: '0.00' } },
  { header: "MRP", key: "MRPQty", style: { numFmt: '0.00' } },
  { header: "MRN", key: "MRNQty", style: { numFmt: '0.00' } },  
  { header: "Total GRN", key: "GRNQty", style: { numFmt: '0.00' } },
  { header: "Total GIN", key: "GINQty", style: { numFmt: '0.00' } },
  { header: "Total MRS", key: "MRSQty", style: { numFmt: '0.00' } },
  { header: "Total MRV", key: "MRVQty", style: { numFmt: '0.00' } },
  { header: "Total MRR", key: "MRRQty", style: { numFmt: '0.00' } },
  { header: "Variance Qty", key: "SumVarQty", style: { numFmt: '0.00' } },
  { header: "Percentage %", key: "SumVarPercent", style: { numFmt: '0.00%' } }
]
;

export const columnListSubWH = [
  {   header: "Dokumen Pabean", key: "PPBDocID" },
  {   header: "No Dokumen ", key: "DocumentID" },
  {   header: "Tanggal Dokumen ", key: "DocumentDate" },
  {   header: "Lokasi Tujuan ", key: "WHLocation" },
  {   header: "Kode Barang ", key: "ItemID" },
  {   header: "Nama Barang", key: "ItemDescription" },
  {   header: "Satuan", key: "UOM" },
  {   header: "Jumlah", key: "Qty", style: { numFmt: '0.00' } },
];

export const columnStockCard = [
  { header: "Tipe Item", key: "ItemType" },
  { header: "Tanggal", key: "DocumentDate" },
  { header: "No Dokumen", key: "DocumentID" },
  { header: "PO", key: "PO"},
  { header: "MO", key: "MO"},
  { header: "Item ID", key: "ItemID" },
  { header: "Item Description", key:"ItemDescription" },
  { header: "Item Serial", key:"ItemSerial"},
  { header: "Qty IN", key:"QtyIN" },
  { header: "Qty OUT", key:"QtyOUT" }
];

export const columnFGMonitoring = [
  { header: "ItemID", key: "ItemID" },
  { header: "ItemDescription", key: "ItemDescription"},
  { header: "UOM", key: "UOM"},
  { header: "FG Impor", key: "FGImport"},
  { header: "IN Produksi", key: "FGProdStart"},
  { header: "OUT Produksi", key: "FGProdFinish"},
  { header: "Varian Produksi", key: "VarProd"},
  { header: "FG Export", key: "FGExport"},
  { header: "FG Balance", key: "FGBalance"},
];

export function generateExcel(sheetName, defColumn, defData) {
  const workbook  = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet(sheetName);

  // Add column to the worksheet
  worksheet.columns = defColumn;

  // add data to the worksheet
  const data = defData;

  data.forEach(row => {
    worksheet.addRow(row);
  });

  // Convert workbook to a buffer
  const buffer = workbook.xlsx.writeBuffer();
  return buffer;
};

export function generateExcelTrace(sheetName1, sheetName2, defColumn1, defColumn2, defData1, defData2) {
  const workbook    = new ExcelJS.Workbook();
  const worksheet1  = workbook.addWorksheet(sheetName1);
  const worksheet2  = workbook.addWorksheet(sheetName2);

  // Add column to the worksheet
  worksheet1.columns = defColumn1;
  worksheet2.columns = defColumn2;

  // add data to the worksheet
  const data1 = defData1;
  const data2 = defData2;

  data1.forEach(row => {
    worksheet1.addRow(row);
  });
  
  data2.forEach(row => {
    worksheet2.addRow(row);
  });

  // Convert workbook to a buffer
  const buffer = workbook.xlsx.writeBuffer();
  return buffer;
};

