import React, { useEffect, useState, useRef }  from "react";
import { useParams } from 'react-router-dom';
import axios from "../../api/api.js"
import moment from "moment-timezone";
import Swal from "sweetalert2";
import { Row, Col, Form, Button } from "react-bootstrap";
import { AgGridReact } from "ag-grid-react";
import { defaultColDef, colDefMutasi } from "../utils/utilsAgGrid.js";
import { renewPinMutasi } from "../utils/utilsCalculate.js";
import { columnListMutasi, generateExcel } from "../utils/utilsExportXLS.js";
import FileSaver from "file-saver";
import CompDateRangeWeek from "../utils/utilsDateSelector.js";
import "ag-grid-community/styles/ag-grid.css"; // Core grid CSS, always needed
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional theme CSS
import "../../styles/app.css";


export function ReportMutasi(){
    // set moment locale to indonesia
    moment.locale("id");
    
    // state management
    const [searchStartDate, setSearchStartDate] = useState(moment().subtract(1,'M').format('YYYY-MM-DD'));
    const [searchEndDate, setSearchEndDate]     = useState(moment().format('YYYY-MM-DD'));
    const [dateSelection, setDateSelection]     = useState({ start: moment(), end: moment()});
    const [dataMutasi, setDataMutasi]           = useState([]);
    const gridRef                               = useRef();
    const { muttype }                           = useParams();

    // function to handle change date selection
    function handleChangeDate(start, end) {
        setDateSelection({ start, end });
        setSearchStartDate(start.format("YYYY-MM-DD"));
        setSearchEndDate(end.format("YYYY-MM-DD"));
        fetchData(muttype, start.format("YYYY-MM-DD"), end.format("YYYY-MM-DD"));
    }

    // function to show pinned bottom row data
    const [pinnedBottomRowData, setPinnedBottomRowData] = useState([
        renewPinMutasi(dataMutasi),
    ]);
    
    // function to set refresh pinned
    function refreshPinned(gridRef, dataResultScan) {
    if (gridRef.current.api) {
            setPinnedBottomRowData([renewPinMutasi(dataResultScan)]);
        }
    }
  

    useEffect(() => {
        if (gridRef) {
            setPinnedBottomRowData([renewPinMutasi(dataMutasi)]);
        }
    }, [dataMutasi, gridRef]);

    function onfiltered(e) {
        const isFilterActive = e.api.isAnyFilterPresent(); //check filter atau tidak
        if (isFilterActive) {
            let dataRender = [];
            e.api.forEachNodeAfterFilter(function (node) {
                dataRender.push(node.data); // Data baris yang lolos dari filter
            });
            return refreshPinned(gridRef, dataRender);
        } else {
            return refreshPinned(gridRef, dataMutasi);
        }
    }

    const fetchData = async(MutType, FilterStartDate, FilterEndDate) => {
        Swal.fire({
            title: "Please wait..",
            didOpen: async () => {
              Swal.showLoading();
              await axios
                .get(`/api/mutasi/${MutType}/${FilterStartDate}/${FilterEndDate}`)
                .then((response) => {
                  if (response.status === 200) {
                    setDataMutasi(response.data.data);
                    Swal.close();
                  }
                })
                .catch((error) => {
                  Swal.close();
                  if (error.response) return console.log("Error get Data");
                });
            },
          }).then((result) => {
            if (result.dismiss === Swal.DismissReason.timer) {
              console.log("I was closed by the timer");
            }
          });
    };
    
    // function to handle button search
    const handleButtonSearch = (event) => {
        event.preventDefault();
        fetchData(searchStartDate, searchEndDate);
    };
    
    // function to handle button download excel report
    const handleButtonDownloadXLS = async (event) => {
        event.preventDefault();
        const sheetName     = "Laporan Mutasi Bahan Baku"
        try{
            const buffer = await generateExcel(sheetName, columnListMutasi, dataMutasi);
            const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            FileSaver.saveAs(blob, `LAPORAN-MUTASI-BAHAN-BAKU-${moment(searchStartDate).format('YYYYMMDD')}-${moment(searchEndDate).format('YYYYMMDD')}.xlsx`);
        } catch(error){
            console.log(error);
        }
    }


    return(
        <div className="content-wrapper">
                <div className="content">
                    <div className="container-fluid px-2">
                        <Row>
                            <Col>
                                <br/>
                                <h5 className="m-0">Laporan Mutasi Bahan Baku  </h5>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={12} md={12} lg={12} className="pt-2 pt-lg-0">
                                <Form onSubmit={handleButtonSearch}>
                                    <Row>
                                        <Col sm={8} md={5} lg={3} className="form-group mb-2 mb-lg-0">
                                            <label htmlFor="srcStartDate" className="form-label" style={{fontSize:"xx-small"}}>Pilih Tanggal: &nbsp;</label>
                                            <CompDateRangeWeek
                                                state={dateSelection}
                                                handleCallback={handleChangeDate}
                                                style={{width: "100%"}}
                                            /> 
                                        </Col>
                                        <Col sm={0} md={6} lg={8} className="form-group mb-2 mb-lg-0">
                                            &nbsp;
                                        </Col>
                                        <Col sm={4} md={1} lg={1} className="form-group mb-2 mb-lg-0 text-right">
                                            <br/>
                                            <Button className="btn btn-small btn-success swalDefaultAccess" onClick={handleButtonDownloadXLS} ><i className="right fas fa-download"></i></Button>
                                        </Col>
                                    </Row>
                                </Form>
                            </Col>    
                        </Row>
                        <Row>
                            <Col className="pt-2 pt-lg-2">
                                <div className="ag-theme-quartz text-center" style={{ height: "80vh", width: "100%", fontSize: "x-small" }} >
                                    <AgGridReact    
                                        ref={gridRef}
                                        onFilterChanged={(e) => onfiltered(e)} 
                                        columnDefs={colDefMutasi} 
                                        rowData={dataMutasi} 
                                        defaultColDef={defaultColDef} 
                                        pagination={'true'}
                                        pinnedBottomRowData={pinnedBottomRowData}></AgGridReact>
                                </div>
                                <br/>
                            </Col>
                        </Row>
                    </div>
            </div>
        </div>
    )
}