import Swal from "sweetalert2";


// show module toast exist 
export const showToastExist = () => {
  Swal.fire({
    icon: 'warning',
    title: 'Nomor Dokumen sudah ada!',
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
  });
}

export const showToastFailSaveHeader = () => {
  Swal.fire({
    icon: 'warning',
    title: 'Header gagal disimpan!',
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
  });
}

// show module toast fail 
export const showToastFail = () => {
  Swal.fire({
    icon: 'warning',
    title: 'Dokumen gagal diinput!',
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
  });
}

// show module toast exist 
export const showToastDelItem = () => {
  Swal.fire({
    icon: 'success',
    title: 'Hapus Item berhasil!',
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
  });
}
