import React, {
  useState,
  useEffect
} from "react";
import {
  useNavigate
} from "react-router-dom";
import axios from "../api/api.js";
import { Container, Row, Col, Form, Button, Image } from "react-bootstrap";

function MainLogin() {
  const [inputUsername, setUsername] = useState("");
  const [inputPassword, setPassword] = useState("");
  const [msg, setMsg] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const cekLogin = async () => {
      return await axios
        .get("/api/auth/token")
        .then((response) => {
          navigate("/app/home");
        })
        .catch((error) => {
          if (error.response) return "";
        });
    }; 
    cekLogin();
  }, [navigate]);
  
  const onChangeUsername = (e) => {
    const value = e.target.value;
    setUsername(value.toLowerCase());
  };

  const onChangePassword = (e) => {
    const value = e.target.value;
    setPassword(value);
  };

  const Auth = async (e) => {
    try {
      e.preventDefault();
      await axios
        .post("/api/auth/login", {
          inputUsername: inputUsername,
          inputPassword: inputPassword,
        })
        .then((response) => {
          return navigate("/app/home");
        })
    } catch (error) {
      return setMsg(`Network Error, Please Contact Your Administrator`);
    }
  };

    return(
        <Container>
          <br/><br/><br/>
          <Row>
            <Col>
            <section className="vh-10">
            <div className="container-fluid h-custom ">
                <Row className="d-flex justify-content-center align-items-center h-50">
                    <br/><br/><br/>
                    <h3><Image src="/images/logos.png" className="img-fluid" alt="Logo Sumbiri" style={{height: "60px"}} /><b>&nbsp; Sumbiri IT Inventory System</b></h3>
                    <hr />
                </Row>
                <Row className="d-flex justify-content-center align-items-center h-1">
                  <Col sm={12} md={9} lg={6} xl={5}>
                    <img src="/images/draw2.webp" className="img-fluid" alt="Gambarku" />
                  </Col>
                    <Col md={8} lg={6} xl={4} className="offset-xl-1">
                      <Form onSubmit={Auth}>
                        <div className="d-flex flex-row align-items-center justify-content-center justify-content-lg-start">  
                        </div>
                        <div className="divider d-flex align-items-center my-4">
                          <p className="lead fw-normal mb-0 me-3"> </p>
                        </div>
                        <div className="form-outline mb-4">
                          <input type="text" id="formUsername" className="form-control form-control-lg" name="inputUsername" value={inputUsername} onChange={onChangeUsername} placeholder="Enter a valid username" required/>
                          <label className="form-label" htmlFor="formUsername">Username</label>
                        </div>
                        <div className="form-outline mb-3">
                          <input type="password" id="formPassword" className="form-control form-control-lg" name="inputPassword" value={inputPassword} onChange={onChangePassword} placeholder="Enter password" required/>
                          <label className="form-label" htmlFor="formPassword">Password</label>
                        </div>
                        <div className="form-outline mb-3">
                          {msg}
                        </div>
                        <div className="text-center text-lg-start mt-4 pt-2">
                          <Button type="submit" className="btn btn-primary btn-lg shadow" style={{paddingLeft: "2.5rem", paddingRight: "2.5rem"}}>SIGN IN</Button>
                        </div>
                        <br/><br/>
                    </Form>
                </Col>
              </Row>
            </div>
            <div className="d-flex flex-column flex-md-row text-center text-md-start justify-content-between py-4 px-4 px-xl-5 bg-primary">
              <div className="text-white mb-3 mb-md-0 text-center">Copyright © 2024 PT Sumber Bintang Rejeki Semarang</div>
            </div>
          </section>

            </Col>
          </Row>
      </Container>
    )
}

export default MainLogin;