import React, {useState, useEffect} from "react";
import axios from "../../api/api.js"
import { Row, Col, Form, Button, Table, Modal } from "react-bootstrap";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css"; // Core grid CSS, always needed
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional theme CSS
import "../../styles/app.css";
import { colDefMasterItem, colDefMasterVendor, defaultColDef } from "../utils/utilsAgGrid.js";
import { getMasterItem, getListItemCategory , getDataUser, getMasterVendor } from "../utils/utilsGetData.js";



export function MainSetting(){
    const [activeTab, setActiveTab]                 = useState('tabMasterUser');
    const [msgAlert, setMsgAlert]                   = useState('');
    const [listUser, setListUser]                   = useState([]);
    const [userData, setUserData]                   = useState([]);
    const [listItem, setListItem]                   = useState([]);
    const [listItemCategory, setListItemCategory]   = useState([]);
    const [inputItem, setInputItem]                 = useState([]);
    const [listVendor, setListVendor]               = useState([]);
    const [inputVendor, setInputVendor]             = useState([]);
    const [inputType, setInputType]                 = useState('');
    const [inputDisable, setInputDisable]           = useState(false);
    const [showModalAccess, setShowModalAccess]     = useState(false);
    const [selectedUserName, setSelectedUserName]   = useState('');
    const [listMenu, setListMenu]                   = useState([]);

    const handleTabClick = (tab) => { setActiveTab(tab) };

    const postDataUser = async(dataUser, inputType) => {
        if(inputType==='put'){
            await axios
            .put(`/api/users`, dataUser)
            .then((response) => {
                if (response.status === 200) {
                setMsgAlert("User Update Success");
                getDataUser();
                }
            })
            .catch((error) => { if (error.response) return console.log("Error update Data")});
        } else {
            await axios
            .post(`/api/users`, dataUser)
            .then((response) => { if (response.status === 200) {
                    setMsgAlert("User Add Success");
                    getDataUser();
                }}
            )
            .catch((error) => { if (error.response) return console.log("Error post Data") });
        }
    };

    const postDataItem = async(dataItem) => {
        await axios
        .post(`/api/data/new-item`, dataItem)
        .then((response) => {
            if (response.status === 200) { 
                setMsgAlert("Tambah Item Berhasil")
            }
        })
        .catch((error) => { if (error.response) return console.log("Error post Data") });
    };

    const postDataVendor = async(dataVendor) => {
        await axios
        .post(`/api/data/vendor`, dataVendor)
        .then((response) => {
            if (response.status === 200) { 
                setMsgAlert("Tambah Vendor / Customer Berhasil") 
            }
        })
        .catch((error) => { if (error.response) return console.log(error.response) });
    };

    const postGrantMenu = async(dataPost) => {
        await axios
        .post(`/api/auth/menu-grant`, dataPost)
        .then((response) => {
            if (response.status === 200) { 
                setMsgAlert("Grant / Revoke menu Berhasil") 
            }
        })
        .catch((error) => { if (error.response) return console.log(error.response) });
    };

    const ocInputUser = (event) => {
        const { name, value } = event.target;
        setUserData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const ocEditUser = (userName, event) => {
       event.preventDefault();
       let filteredData = listUser.filter(obj => obj.userName === userName);
       setInputType('put');
       setInputDisable(true);
       setUserData(filteredData[0]);
    }

    
    const ocInputItem = async(event) => {
        const { name, value } = event.target;
        if(name==='ItemTypeCode'){
            const getItemCategory  = async (value) => {
                try{
                    const resultCategory = await getListItemCategory(value);
                    setListItemCategory(resultCategory);
                } catch(err){
                    console.error('Error fetching data:', err);
                }
            }
            getItemCategory(value);
        }
        setInputItem(prevState => ({
            ...prevState,
            [name]: value
        }));
    }
     

    const ocInputVendor = (event) => {
        const { name, value } = event.target;
        setInputVendor(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    
    // handle form button submit
    const handleButtonAddUser = (event) => {
        event.preventDefault();
        postDataUser(userData, inputType);
    };

    const handleButtonAddItem = async(event) => {
        event.preventDefault();
        await postDataItem(inputItem);
        await getMasterItem();
    };

    const handleButtonAddVendor = (event) => {
        event.preventDefault();
        postDataVendor(inputVendor);
    };

    const fetchListMenu = async (userName) => {
        try {
            const response  = await axios.get(`/api/auth/menu-access/${userName}`);
            const dataRes   = response.data.data;
            const convertedData = dataRes.map(item => ({
                ...item,
                hasAccess: item.hasAccess === 'true'
            }));
            setListMenu(convertedData);
        } catch(error){ console.error('Error fetching data:', error)};
    };


    const ocBtnModalAccess = (event, userName) => {
        event.preventDefault();
        fetchListMenu(userName);
        setSelectedUserName(userName);
        setShowModalAccess(true);
    }

    const ocSetAcessMenu = (event, menuId, userId) => {
        var dataPost;
        const newAccess     = event.target.checked;
        setListMenu(prevListMenu =>
            prevListMenu.map(item =>
              item.menuId === menuId && item.userId === userId ? { ...item, hasAccess: newAccess } : item
            )
        );
        if(newAccess===true){
            dataPost = { postType:'GRANT', menuId: menuId, userId: userId};
        } else {
            dataPost = { postType:'REVOKE', menuId: menuId, userId: userId};
        }
        postGrantMenu(dataPost);
    }
    
    
    function handleCloseModal(){
        setListMenu([]);
        setShowModalAccess(false);
    }
    
    
    
    useEffect(() => {
        const getDataMaster = async () => {
            try {
              const resultUser      = await getDataUser();
              const resultItem      = await getMasterItem();
              const resultVendor    = await getMasterVendor();
              setListUser(resultUser);
              setListItem(resultItem);
              setListVendor(resultVendor);
            } catch (error) {
              console.error('Error fetching data:', error);
            }
        };
        getDataMaster();
    }, []);
    
    
    return(
        <div className="content-wrapper">
            <div className="content">
                <div className="container-fluid px-2">
                    <Row>
                        <Col sm={12} md={12} lg={12}>
                            <br/>
                            <h5 className="m-0">Pengaturan</h5>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="pt-2 pt-lg-2">
                            <ul className="nav nav-tabs">
                                <li className="nav-item"><button className={`nav-link ${activeTab === 'tabMasterUser' ? 'active' : ''}`} onClick={() => handleTabClick('tabMasterUser')}>User Management</button></li>
                                <li className="nav-item"><button className={`nav-link ${activeTab === 'tabMasterItem' ? 'active' : ''}`} onClick={() => handleTabClick('tabMasterItem')}>Master Item</button></li>
                                <li className="nav-item"><button className={`nav-link ${activeTab === 'tabMasterVendor' ? 'active' : ''}`} onClick={() => handleTabClick('tabMasterVendor')}>Master Vendor / Customer</button></li>
                            </ul>
                            <div className="tab-content">
                                <div className={`tab-pane ${activeTab === 'tabMasterUser' ? 'active' : ''}`}>
                                    <Form onSubmit={handleButtonAddUser}>
                                        <Row>
                                            <Col sm={12} md={12} lg={12} className="form-group">
                                                <br/><h6>Tambah User {msgAlert}</h6>
                                            </Col>
                                            <Col sm={12} md={4} lg={2} className="px-1 form-group">
                                                <label htmlFor="srcUsername" className="form-label" style={{fontSize:"x-small"}}>Username</label><br/>
                                                <input id="srcUsername" type="text" className="form-control form-control-sm" value={userData.userName} name="userName" disabled={inputDisable} onChange={ocInputUser}/>
                                            </Col>
                                            <Col sm={12} md={4} lg={2} className="px-1 form-group">
                                                <label htmlFor="srcPassword" className="form-label" style={{fontSize:"x-small"}}>Password</label><br/>
                                                <input id="srcPassword" type="password" className="form-control form-control-sm" name="userPass" onChange={ocInputUser}/>
                                            </Col>
                                            <Col sm={12} md={4} lg={2} className="px-1 form-group">
                                                <label htmlFor="srcFullname" className="form-label" style={{fontSize:"x-small"}}>Nama Lengkap</label><br/>
                                                <input id="srcFullname" type="text" className="form-control form-control-sm" value={userData.userFullName} name="userFullName" onChange={ocInputUser}/>
                                            </Col>
                                            <Col sm={12} md={4} lg={2} className="px-1 form-group">
                                                <label htmlFor="srcDept" className="form-label" style={{fontSize:"x-small"}}>Departemen</label><br/>
                                                <input id="srcDept" type="text" className="form-control form-control-sm" value={userData.userDept} name="userDept" onChange={ocInputUser}/>
                                            </Col>
                                            <Col sm={12} md={4} lg={2} className="px-1 form-group">
                                                <label htmlFor="srcPosition" className="form-label" style={{fontSize:"x-small"}}>Posisi</label><br/>
                                                <input id="srcPosition" type="text" className="form-control form-control-sm" value={userData.userPosition} name="userPosition" onChange={ocInputUser}/>
                                            </Col>
                                            <Col sm={12} md={4} lg={2} className="px-1 form-group">
                                                <label htmlFor="srcPhone" className="form-label" style={{fontSize:"x-small"}}>Telp</label><br/>
                                                <input id="srcPhone" type="text" className="form-control form-control-sm" value={userData.userPhone} name="userPhone" onChange={ocInputUser}/>
                                            </Col>
                                            <Col sm={12} md={4} lg={2} className="px-1 form-group">
                                                <label htmlFor="srcEmail" className="form-label" style={{fontSize:"x-small"}}>Email</label><br/>
                                                <input id="srcEmail" type="text" className="form-control form-control-sm" name="userEmail" onChange={ocInputUser}/>
                                            </Col>
                                            <Col sm={12} md={4} lg={2} className="px-1 form-group">
                                                <br/><Button className="btn btn-primary btn-small" type="submit" name="btnAddUser" ><i className="right fas fa-save"></i></Button>
                                            </Col>
                                        </Row>
                                    </Form>
                                    <Table className="table table-striped table-hover" style={{fontSize: "small"}}>
                                        <thead className="bg-primary">
                                            <tr>
                                                <th scope="col">Username</th>
                                                <th scope="col">Nama Lengkap</th>
                                                <th scope="col">Departemen</th>
                                                <th scope="col">Posisi</th>
                                                <th scope="col">Telepon</th>
                                                <th scope="col">Email</th>
                                                <th scope="col">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        {listUser.map((users) => (
                                            <tr key={users.userName}>
                                                <td>{users.userName}</td>
                                                <td>{users.userFullName}</td>
                                                <td>{users.userDept}</td>
                                                <td>{users.userPosition}</td>
                                                <td>{users.userPhone}</td>
                                                <td>{users.userEmail}</td>
                                                <td>
                                                    <Button className="btn btn-sm btn-success" onClick={e => ocEditUser(users.userName, e)} style={{fontSize:"xx-small"}}><i className="right fas fa-edit"></i></Button> &nbsp;
                                                    <Button className="btn btn-sm btn-danger" style={{fontSize:"xx-small"}}><i className="right fas fa-trash"></i></Button> &nbsp;
                                                    <Button className="btn btn-sm btn-warning" onClick={e => ocBtnModalAccess(e, users.userName)} style={{fontSize:"xx-small"}}><i className="right fas fa-universal-access"></i></Button>
                                                </td>
                                            </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                </div>
                                <div className={`tab-pane ${activeTab === 'tabMasterItem' ? 'active' : ''}`}>
                                    <Form onSubmit={handleButtonAddItem}>
                                        <Row>
                                            <Col sm={12} md={12} lg={12} className="form-group">
                                                <br/><h6>Tambah Item {msgAlert}</h6>
                                            </Col>
                                            <Col sm={12} md={4} lg={2} className="px-1 form-group">
                                                <label htmlFor="srcItemTypeCode" className="form-label" style={{fontSize:"x-small"}}>Item Type Code</label><br/>
                                                <select className="form-control form-control-sm font-xsmall" id="srcItemTypeCode" name="ItemTypeCode" onChange={ocInputItem} required>
                                                    <option value={"MATERIAL"}>MATERIAL</option>
                                                    <option value={"EQUIPMENT"}>EQUIPMENT</option>
                                                    <option value={"FINISH GOOD"}>FINISH GOOD</option>
                                                    <option value={"WASTE"}>WASTE</option>
                                                </select>
                                            </Col>
                                            <Col sm={12} md={4} lg={2} className="px-1 form-group">
                                                <label htmlFor="srcItemCategoryCode" className="form-label" style={{fontSize:"x-small"}}>Item Category Code</label><br/>
                                                <select className="form-control form-control-sm font-xsmall" id="srcItemCategoryCode" name="ItemCategoryCode" onChange={ocInputItem} required>
                                                    {listItemCategory.map((category) => (
                                                        <option value={category.ItemCategoryCode}>{category.ItemCategoryCode}</option>
                                                    ))}
                                                </select>
                                            </Col>
                                            <Col sm={12} md={4} lg={2} className="px-1 form-group">
                                                <label htmlFor="srcItemID" className="form-label" style={{fontSize:"x-small"}}>Item ID</label><br/>
                                                <input id="srcItemID" type="text" className="form-control form-control-sm"  name="ItemID" onChange={ocInputItem} required/>
                                            </Col>
                                            <Col sm={12} md={4} lg={2} className="px-1 form-group">
                                                <label htmlFor="srcItemDescription" className="form-label" style={{fontSize:"x-small"}}>Item Description</label><br/>
                                                <input id="srcItemDescription" type="text" className="form-control form-control-sm"  name="ItemDescription"onChange={ocInputItem} required/>
                                            </Col>
                                            <Col sm={12} md={4} lg={2} className="px-1 form-group">
                                                <br/><Button className="btn btn-primary btn-small" type="submit" name="btnAddItem" ><i className="right fas fa-save"></i></Button>
                                            </Col>
                                        </Row>
                                    </Form>
                                    <div className="ag-theme-quartz" style={{ height: "80vh", width: "100%", fontSize: "x-small" }} >
                                        <AgGridReact 
                                            columnDefs={colDefMasterItem} 
                                            rowData={listItem} 
                                            defaultColDef={defaultColDef} 
                                            pagination={'true'}
                                        ></AgGridReact>
                                    </div>
                                </div>
                                <div className={`tab-pane ${activeTab === 'tabMasterVendor' ? 'active' : ''}`}>
                                    <Form onSubmit={handleButtonAddVendor}>
                                        <Row>
                                            <Col sm={12} md={12} lg={12} className="form-group">
                                                <br/><h6>Tambah Vendor / Customer <i>{msgAlert}</i></h6>
                                            </Col>
                                            <Col sm={12} md={4} lg={2} className="px-1 form-group">
                                                <label htmlFor="srcVendorName" className="form-label" style={{fontSize:"x-small"}}>Nama Vendor / Customer</label><br/>
                                                <input className="form-control form-control-sm" list="listVendorName" id="srcVendorName" name="VendorName" onChange={ocInputVendor} required/>
                                                <datalist id="listVendorName">
                                                {listVendor.map((vendor) => (
                                                    <option value={vendor.VendorName} />
                                                ))}
                                                </datalist>
                                            </Col>
                                            <Col sm={12} md={4} lg={2} className="px-1 form-group">
                                                <label htmlFor="srcCountryID" className="form-label" style={{fontSize:"x-small"}}>Negara Asal</label><br/>
                                                <input id="srcCountryID" type="text" className="form-control form-control-sm"  name="CountryID" onChange={ocInputVendor} maxLength={2} required/>
                                            </Col>
                                            <Col sm={12} md={4} lg={2} className="px-1 form-group">
                                                <br/><Button className="btn btn-primary btn-small" type="submit" name="btnAddVendor" ><i className="right fas fa-save"></i></Button>
                                            </Col>
                                        </Row>
                                    </Form>
                                    <div className="ag-theme-quartz" style={{ height: "80vh", width: "100%", fontSize: "x-small" }} >
                                        <AgGridReact 
                                            columnDefs={colDefMasterVendor} 
                                            defaultColDef={defaultColDef} 
                                            rowData={listVendor}
                                            pagination={'true'}
                                        ></AgGridReact>
                                    </div>
                                </div>
                            </div><br/>
                        </Col>
                    </Row>
                </div>
            </div>
            <Modal show={showModalAccess} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                        <h5>Edit User Access : {selectedUserName}</h5>
                </Modal.Header>
                <Modal.Body>
                    <table className="table table-striped">
                        <tbody>
                        {listMenu.map((item, index) => (
                            <tr key={index}>
                                <td>{item.menuId} - {item.menuTitle}</td>
                                <td className="form-check form-switch"><input className="form-check-input" checked={item.hasAccess} onChange={(event) => ocSetAcessMenu(event, item.menuId, item.userId)} type="checkbox" role="switch" id="flexSwitchCheckDefault"/></td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                
                </Modal.Body>
            </Modal>
        </div>
    )
}