import React, { useState }  from "react";
import { Row, Col, Form, } from 'react-bootstrap';
import axios from "../../api/api.js";
import { defaultColDef, colDefInOutSubWH, colDefMutasi } from "../utils/utilsAgGrid";
import { AgGridReact } from "ag-grid-react";
import moment from "moment-timezone";
import Swal from "sweetalert2";
import { columnListMutasi, columnListSubWH, generateExcel } from "../utils/utilsExportXLS.js";
import FileSaver from "file-saver";
import CompDateRangeWeek from "../utils/utilsDateSelector.js";
import "ag-grid-community/styles/ag-grid.css"; // Core grid CSS, always needed
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional theme CSS
import '../../App.css';
import "../../styles/app.css";


export function ReportSubWarehouse(){
    const [activeTab, setActiveTab]                     = useState('tab1');
    const [dateSelection, setDateSelection]             = useState({ start: moment(), end: moment()});
    const [dataHeaderSubWHIn, setDataHeaderSubWHIn]     = useState([]);
    const [dataHeaderSubWHOut, setDataHeaderSubWHOut]   = useState([]);
    const [dataMutSubWH, setDataMutSubWH]               = useState([]);
    

    const fetchHeaderSubWHIN = async(FilterStartDate, FilterEndDate) => {
        Swal.fire({
            title: "Please wait..",
            didOpen: async () => {
              Swal.showLoading();
              await axios
                .get(`/api/subwh/in/${FilterStartDate}/${FilterEndDate}`)
                .then((response) => {
                  if (response.status === 200) {
                    setDataHeaderSubWHIn(response.data.data);
                    Swal.close();
                  }
                })
                .catch((error) => {
                  Swal.close();
                  if (error.response) return console.log("Error get Data");
                });
            },
          }).then((result) => { if (result.dismiss === Swal.DismissReason.timer) { console.log("I was closed by the timer") }});
    };

    const fetchHeaderSubWHOUT = async(FilterStartDate, FilterEndDate) => {
        Swal.fire({
            title: "Please wait..",
            didOpen: async () => {
              Swal.showLoading();
              await axios
                .get(`/api/subwh/out/${FilterStartDate}/${FilterEndDate}`)
                .then((response) => {
                  if (response.status === 200) {
                    setDataHeaderSubWHOut(response.data.data);
                    Swal.close();
                  }
                })
                .catch((error) => {
                  Swal.close();
                  if (error.response) return console.log("Error get Data");
                });
            },
          }).then((result) => { if (result.dismiss === Swal.DismissReason.timer) { console.log("I was closed by the timer") }});
    };

    const fetchMutSubWH = async(FilterStartDate, FilterEndDate) => {
        Swal.fire({
            title: "Please wait..",
            didOpen: async () => {
              Swal.showLoading();
              await axios
                .get(`/api/mutasi/subwh/${FilterStartDate}/${FilterEndDate}`)
                .then((response) => {
                  if (response.status === 200) {
                    setDataMutSubWH(response.data.data);
                    Swal.close();
                  }
                })
                .catch((error) => {
                  Swal.close();
                  if (error.response) return console.log("Error get Data");
                });
            },
          }).then((result) => { if (result.dismiss === Swal.DismissReason.timer) { console.log("I was closed by the timer") }});
    };

    

    
    const handleTabClick = (tab) => {
        setActiveTab(tab);
    };

    function handleChangeDateIN(start, end) {
        setDateSelection({ start, end });
        fetchHeaderSubWHIN(start.format("YYYY-MM-DD"), end.format("YYYY-MM-DD"));
    }
    
    function handleChangeDateOUT(start, end) {
        setDateSelection({ start, end });
        fetchHeaderSubWHOUT(start.format("YYYY-MM-DD"), end.format("YYYY-MM-DD"));
    }

    function handleChangeDateMut(start, end) {
        setDateSelection({ start, end });
        fetchMutSubWH(start.format("YYYY-MM-DD"), end.format("YYYY-MM-DD"));
    }


    
    const handleButtonDownloadXLSSubWHin = async (event) => {
        event.preventDefault();
        const sheetName     = "Laporan Penerimaan Barang dari Gudang Pusat"
        try{
            const buffer = await generateExcel(sheetName, columnListSubWH, dataHeaderSubWHIn);
            const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            FileSaver.saveAs(blob, `LAPORAN-SUBWH-IN.xlsx`);
        } catch(error){
            console.log(error);
        }
    }

    const handleButtonDownloadXLSSubWHout = async (event) => {
        event.preventDefault();
        const sheetName     = "Laporan Pengiriman Barang ke Gudang Pusat"
        try{
            const buffer = await generateExcel(sheetName, columnListSubWH, dataHeaderSubWHOut);
            const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            FileSaver.saveAs(blob, `LAPORAN-SUBWH-OUT.xlsx`);
        } catch(error){
            console.log(error);
        }
    }

    const handleButtonDownloadXLSMutasi = async (event) => {
        event.preventDefault();
        const sheetName     = "Laporan Mutasi Gudang Luar Area"
        try{
            const buffer = await generateExcel(sheetName, columnListMutasi, dataMutSubWH);
            const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            FileSaver.saveAs(blob, `LAPORAN-MUTASI-GUDANG-LUAR-AREA.xlsx`);
        } catch(error){
            console.log(error);
        }
    }


    return(
        <div className="content-wrapper">
            <div className="content">
                <div className="container-fluid px-2">
                    <Row>
                        <Col className="pt-2 pt-lg-2">
                            <ul className="nav nav-tabs">
                                <li className="nav-item">
                                    <button className={`nav-link ${activeTab === 'tab1' ? 'active font-weight-bold' : ''}`} onClick={() => handleTabClick('tab1')} style={{fontSize: 'x-small'}}>Log Pemasukan Gudang Luar Area</button>
                                </li>
                                <li className="nav-item">
                                    <button className={`nav-link ${activeTab === 'tab2' ? 'active font-weight-bold' : ''}`} onClick={() => handleTabClick('tab2')} style={{fontSize: 'x-small'}}>Log Pengeluaran Gudang Luar Area</button>
                                </li>
                                <li className="nav-item">
                                    <button className={`nav-link ${activeTab === 'tab3' ? 'active font-weight-bold' : ''}`} onClick={() => handleTabClick('tab3')} style={{fontSize: 'x-small'}}>Mutasi Gudang Luar Area</button>
                                </li>
                            </ul>
                            <div className="tab-content">
                                <div className={`tab-pane ${activeTab === 'tab1' ? 'active bg-white' : ''}`}>
                                    <Row><Col><br/><h5>Pemasukan Gudang Luar Area</h5></Col></Row>
                                    <Row>
                                        <Col sm={12} md={12} lg={12}  className="pt-lg-0">
                                            <Form>
                                                <Row>
                                                    <Col sm={12} md={4} lg={4} className="form-group">
                                                        <label htmlFor="srcStartDate" className="form-label" style={{fontSize:"xx-small"}}>Pilih Tanggal: &nbsp;</label>
                                                        <CompDateRangeWeek
                                                            state={dateSelection}
                                                            handleCallback={handleChangeDateIN}
                                                            style={{width: "100%"}}
                                                        /> 
                                                    </Col>
                                                    <Col sm={12} md={8} lg={8} className="form-group dropdown">
                                                        <br/>
                                                        <button className="btn btn-small btn-success swalDefaultAccess"  onClick={handleButtonDownloadXLSSubWHin} ><i className="right fas fa-download"></i></button>
                                                    </Col>
                                                </Row>
                                            </Form>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <div className="ag-theme-quartz text-center" style={{ height: "80vh", width: "100%", fontSize: "x-small" }} >
                                                <AgGridReact 
                                                    columnDefs={colDefInOutSubWH} 
                                                    rowData={dataHeaderSubWHIn || []} 
                                                    defaultColDef={defaultColDef} 
                                                    pagination={'true'}
                                                    >
                                                </AgGridReact>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                                <div className={`tab-pane ${activeTab === 'tab2' ? 'active' : ''}`}>
                                    <Row><Col><br/><h5>Pengeluaran Gudang Luar Area</h5></Col></Row>
                                    <Row>
                                        <Col sm={12} md={12} lg={12}  className="pt-lg-0">
                                            <Form>
                                                <Row>
                                                    <Col sm={12} md={4} lg={4} className="form-group">
                                                        <label htmlFor="srcStartDate" className="form-label" style={{fontSize:"xx-small"}}>Pilih Tanggal: &nbsp;</label>
                                                        <CompDateRangeWeek
                                                            state={dateSelection}
                                                            handleCallback={handleChangeDateOUT}
                                                            style={{width: "100%"}}
                                                        /> 
                                                    </Col>
                                                    <Col sm={12} md={8} lg={8} className="form-group dropdown">
                                                        <br/>
                                                        <button className="btn btn-small btn-success swalDefaultAccess" onClick={handleButtonDownloadXLSSubWHout}  ><i className="right fas fa-download"></i></button>
                                                    </Col>
                                                </Row>
                                            </Form>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <div className="ag-theme-quartz text-center" style={{ height: "80vh", width: "100%", fontSize: "x-small" }} >
                                                <AgGridReact 
                                                    columnDefs={colDefInOutSubWH} 
                                                    rowData={dataHeaderSubWHOut || []} 
                                                    defaultColDef={defaultColDef} 
                                                    pagination={'true'}
                                                >
                                                </AgGridReact>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                                <div className={`tab-pane ${activeTab === 'tab3' ? 'active' : ''}`}>
                                    <Row><Col><br/><h5>Mutasi Gudang Luar Area</h5></Col></Row>
                                    <Row>
                                        <Col sm={12} md={12} lg={12}  className="pt-lg-0">
                                            <Form>
                                                <Row>
                                                    <Col sm={12} md={4} lg={4} className="form-group">
                                                        <label htmlFor="srcStartDate" className="form-label" style={{fontSize:"xx-small"}}>Pilih Tanggal: &nbsp;</label>
                                                        <CompDateRangeWeek
                                                            state={dateSelection}
                                                            handleCallback={handleChangeDateMut}
                                                            style={{width: "100%"}}
                                                        /> 
                                                    </Col>
                                                    <Col sm={12} md={8} lg={8} className="form-group dropdown">
                                                        <br/>
                                                        <button className="btn btn-small btn-success swalDefaultAccess" onClick={handleButtonDownloadXLSMutasi}  ><i className="right fas fa-download"></i></button>
                                                    </Col>
                                                </Row>
                                            </Form>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <div className="ag-theme-quartz text-center" style={{ height: "80vh", width: "100%", fontSize: "x-small" }} >
                                                <AgGridReact 
                                                    columnDefs={colDefMutasi} 
                                                    rowData={dataMutSubWH || []} 
                                                    defaultColDef={defaultColDef} 
                                                    pagination={'true'}
                                                >
                                                </AgGridReact>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </div>        
                        </Col>
                    </Row>
                </div>
            </div>
        </div>
    )
}