import React, { useState, useEffect, useRef }  from "react";
import { Row, Col, Form, Button, Modal, Table } from 'react-bootstrap';
import axios from "../../api/api.js";
import { defaultColDef, colDefLogMaterialInbound, colItemInbound, colItemOutbound, colDefLogMaterialOutbound, colDefLogProdIN } from "../utils/utilsAgGrid";
import { AgGridReact } from "ag-grid-react";
import moment from "moment-timezone";
import Swal from "sweetalert2";
import { columnListSummary, columnLogProd, generateExcel } from "../utils/utilsExportXLS.js";
import { renewPin } from "../utils/utilsCalculate.js";
import { Link } from "react-router-dom";
import CompDateRangeWeek from "../utils/utilsDateSelector.js";
import FileSaver from "file-saver";
import * as XLSX from 'xlsx';
import "ag-grid-community/styles/ag-grid.css"; // Core grid CSS, always needed
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional theme CSS
import '../../App.css';
import "../../styles/app.css";
import { useNavigate } from 'react-router-dom';
import { formatArrHeaderDoc } from "../utils/utilsGetData.js";

export function PageReview(){
    const [activeTab, setActiveTab]                     = useState('tab1');
    const [dateSelection, setDateSelection]             = useState({ start: moment(), end: moment()});
    const [showModalLogIN, setShowModalLogIN]           = useState(false);
    const [showModalDetailOUT, setShowModalDetailOUT]   = useState(false);
    const [showModalDelete, setShowModalDelete]         = useState(false);
    const [showModalDelSuccess, setShowModalDelSuccess] = useState(false);
    const [dataLogIN, setDataLogIN]                     = useState([]);
    const [summaryLogIN, setSummaryLogIN]               = useState([]);
    const [summaryLogOUT, setSummaryLogOUT]             = useState([]);
    const [dataLogOUT, setDataLogOUT]                   = useState([]);
    const [dataProdIN, setDataProdIN]                   = useState([]);
    const [dataProdOUT, setDataProdOUT]                 = useState([]);
    const [xlsUploadIN, setXLSUploadIN]                 = useState([]);
    const [OptInType, setOptInType]                     = useState([]);
    const [OptOutType, setOptOutType]                   = useState([]);
    const [selectedDocType, setSelectedDocType]         = useState('');
    const [selectedDocID, setSelectedDocID]             = useState('');
    const [selectedDocINOUT, setSelectedDocINOUT]       = useState('');
    const [pinBtmRowData, setPinBtmRowData]             = useState([renewPin([]),]);
    const [dataLogHeaderIN, setDataLogHeaderIN]         = useState(formatArrHeaderDoc);
    const [dataLogHeaderOUT, setDataLogHeaderOUT]       = useState(formatArrHeaderDoc);
    const [dataLogDetailIN, setDataLogDetailIN]         = useState([formatArrHeaderDoc]);
    const [dataLogDetailOUT, setDataLogDetailOUT]       = useState([formatArrHeaderDoc]);
    
    const navigate = useNavigate();
    const gridRef = useRef();
    
    
        

    
    const handleTabClick = (tab) => { setActiveTab(tab)};

    function refreshPinned(gridRef, dataResultScan) {
        if (gridRef.current.api) {
            setPinBtmRowData([renewPin(dataResultScan)]);
        }
    }
  

    const fetchDataHeaderIN = async(FilterStartDate, FilterEndDate) => {
        Swal.fire({
            title: "Please wait..",
            didOpen: async () => {
                Swal.showLoading();
                await axios
                    .get(`/api/log/inbound/all-detail/${FilterStartDate}/${FilterEndDate}`)
                    .then((response) => {
                        if (response.status === 200) {
                        setSummaryLogIN(response.data.data);
                    }
                })
                .catch((error) => {
                    if (error.response) return console.log("Error get Data");
                });
                await axios
                    .get(`/api/log/inbound/header/${FilterStartDate}/${FilterEndDate}`)
                    .then((response) => {
                        if (response.status === 200) {
                        setDataLogIN(response.data.data);
                        Swal.close();
                    }
                })
                .catch((error) => {
                  Swal.close();
                  if (error.response) return console.log("Error get Data");
                });
            },
          }).then((result) => { if (result.dismiss === Swal.DismissReason.timer) { console.log("I was closed by the timer") }});
    };

    const fetchDataHeaderOUT = async(FilterStartDate, FilterEndDate) => {
        Swal.fire({
            title: "Please wait..",
            didOpen: async () => {
              Swal.showLoading();
              await axios
                    .get(`/api/log/outbound/all-detail/${FilterStartDate}/${FilterEndDate}`)
                    .then((response) => {
                        if (response.status === 200) { setSummaryLogOUT(response.data.data) }
                    })
                    .catch((error) => { if (error.response) return console.log("Error get Data")});
              await axios
                .get(`/api/log/outbound/header/${FilterStartDate}/${FilterEndDate}`)
                .then((response) => {
                  if (response.status === 200) {
                    setDataLogOUT(response.data.data);
                    Swal.close();
                  }
                })
                .catch((error) => {
                  Swal.close();
                  if (error.response) return console.log("Error get Data");
                });
            },
          }).then((result) => { if (result.dismiss === Swal.DismissReason.timer) { console.log("I was closed by the timer") }});
    };

    const fetchDataHeaderProdIN = async(FilterStartDate, FilterEndDate) => {
        Swal.fire({
            title: "Please wait..",
            didOpen: async () => {
              Swal.showLoading();
              await axios
                .get(`/api/log/production/header/in/${FilterStartDate}/${FilterEndDate}`)
                .then((response) => {
                  if (response.status === 200) {
                    setDataProdIN(response.data.data);
                    Swal.close();
                  }
                })
                .catch((error) => {
                  Swal.close();
                  if (error.response) return console.log("Error get Data");
                });
            },
          }).then((result) => { if (result.dismiss === Swal.DismissReason.timer) { console.log("I was closed by the timer") }});
    };

    // fetch data header 
    const fetchDataHeaderProdOUT = async(FilterStartDate, FilterEndDate) => {
        Swal.fire({
            title: "Please wait..",
            didOpen: async () => {
              Swal.showLoading();
              await axios
                .get(`/api/log/production/header/out/${FilterStartDate}/${FilterEndDate}`)
                .then((response) => {
                  if (response.status === 200) {
                    setDataProdOUT(response.data.data);
                    Swal.close();
                  }
                })
                .catch((error) => {
                  Swal.close();
                  if (error.response) return console.log("Error get Data");
                });
            },
          }).then((result) => { if (result.dismiss === Swal.DismissReason.timer) { console.log("I was closed by the timer") }});
    };


    const fetchDataDetailIN = async(docId) => {
        await axios
            .get(`/api/inbound/header/${encodeURIComponent(docId)}`)
            .then((response) => { 
                if(response.status === 200) { 
                    setDataLogHeaderIN(response.data.data[0]);
                    setDataLogHeaderOUT({ CustomsDocType: '', CustomsDocID: '', CustomsDocDate: '', DocumentID: '', DocumentDate: '', VendorName: '', DocumentType: '', Origin:'', CreateBy: '', CreateDate:'', UpdateBy:'', UpdateDate:'' });
                }})
            .catch((error) => { if (error.response) return console.log("Error get Data")});
        await axios
            .get(`/api/inbound/detail/${encodeURIComponent(docId)}`)
            .then((response) => { 
                if (response.status === 200) { 
                    setDataLogDetailIN(response.data.data);
                    setDataLogDetailOUT([{ CustomsDocType: '', CustomsDocID: '', CustomsDocDate: '', DocumentID: '', DocumentDate: '', VendorName: '', DocumentType: '', Origin:'', CreateBy: '', CreateDate:'', UpdateBy:'', UpdateDate:'' }]);
                }})
            .catch((error) => { if (error.response) return console.log("Error get Data")});
    }

    const fetchDataDetailOUT = async(docId) => {
        await axios
            .get(`/api/outbound/header/${encodeURIComponent(docId)}`)
            .then((response) => {
                if (response.status === 200) {
                    setDataLogHeaderOUT(response.data.data[0]);
                    setDataLogHeaderIN([{ CustomsDocType: '', CustomsDocID: '', CustomsDocDate: '', DocumentID: '', DocumentDate: '', VendorName: '', DocumentType: '', Origin:'', CreateBy: '', CreateDate:'', UpdateBy:'', UpdateDate:'' }]);
                }
            })
            .catch((error) => {
                if (error.response) return console.log("Error get Data");
            });
        await axios
            .get(`/api/outbound/detail/${encodeURIComponent(docId)}`)
            .then((response) => {
                if (response.status === 200) {
                    setDataLogDetailOUT(response.data.data);
                    setDataLogDetailIN([{ CustomsDocType: '', CustomsDocID: '', CustomsDocDate: '', DocumentID: '', DocumentDate: '', VendorName: '', DocumentType: '', Origin:'', CreateBy: '', CreateDate:'', UpdateBy:'', UpdateDate:'' }]);
                }
            })
            .catch((error) => {
                if (error.response) return console.log("Error get Data");
            });
    }
    
    

    function handleChangeDateIN(start, end) {
        setDateSelection({ start, end });
        fetchDataHeaderIN(start.format("YYYY-MM-DD"), end.format("YYYY-MM-DD"));
    }
    
    function handleChangeDateOUT(start, end) {
        setDateSelection({ start, end });
        fetchDataHeaderOUT(start.format("YYYY-MM-DD"), end.format("YYYY-MM-DD"));
    }

    function handleChangeDateProdIN(start, end) {
        setDateSelection({ start, end });
        fetchDataHeaderProdIN(start.format("YYYY-MM-DD"), end.format("YYYY-MM-DD"));
    }

    function handleChangeDateProdOUT(start, end) {
        setDateSelection({ start, end });
        fetchDataHeaderProdOUT(start.format("YYYY-MM-DD"), end.format("YYYY-MM-DD"));
    }

    function handleDelDoc(DocumentID){
        setShowModalDelete(true);
    }

    async function clickDelDoc(){
        const doctype   = encodeURIComponent(selectedDocType);
        const docid     = encodeURIComponent(selectedDocID);
        if(selectedDocINOUT==='IN'){
            const delDoc = await axios.delete(`/api/inbound/delete-doc/${doctype}/${docid}`); 
        } else {
            const delDoc = await axios.delete(`/api/outbound/delete-doc/${doctype}/${docid}`);
        }
        setShowModalDelSuccess(true);
        navigate(`/app/review`);
    }

    const handleRowLogINClick = (event) => {
        const rowData = event.data;
        fetchDataDetailIN(rowData.DocumentID);
        setSelectedDocINOUT('IN');
        setSelectedDocType(rowData.DocumentType);
        setSelectedDocID(rowData.DocumentID);
        if (gridRef) setPinBtmRowData([renewPin(dataLogDetailIN)]);
        setShowModalLogIN(true);
    };

    const handleRowLogOUTClick = (event) => {
        const rowData = event.data;
        fetchDataDetailOUT(rowData.DocumentID);
        setSelectedDocINOUT('OUT');
        setSelectedDocType(rowData.DocumentType);
        setSelectedDocID(rowData.DocumentID);
        if (gridRef) setPinBtmRowData([renewPin(dataLogDetailOUT)]);
        setShowModalDetailOUT(true);
    };
 
    function handleCloseModal(){
        setShowModalLogIN(false);
        setShowModalDetailOUT(false);
        setShowModalDelete(false);
        setShowModalDelSuccess(false);
    }

    const handleCloseModalDelete = () => setShowModalDelete(false);


    const handleButtonDownloadXLSPemasukan = async (event) => {
        event.preventDefault();
        const sheetName     = "Laporan Pemasukan Barang";
        try{
            const buffer = await generateExcel(sheetName, columnListSummary, summaryLogIN);
            const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            FileSaver.saveAs(blob, `LAPORAN-PEMASUKAN-${moment(dateSelection).format('YYYYMMDD')}.xlsx`);
        } catch(error){
            console.log(error);
        }
    }

    const handleButtonDownloadXLSPengeluaran = async (event) => {
        event.preventDefault();
        const sheetName     = "Laporan Pengeluaran Barang";
        try{
            const buffer = await generateExcel(sheetName, columnListSummary, summaryLogOUT);
            const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            FileSaver.saveAs(blob, `LAPORAN-PENGELUARAN-${moment(dateSelection).format('YYYYMMDD')}.xlsx`);
        } catch(error){
            console.log(error);
        }
    }

    const handleButtonDownloadXLSProdIN = async (event) => {
        event.preventDefault();
        const sheetName     = "Laporan Pemasukan Produksi";
        try{
            const buffer = await generateExcel(sheetName, columnLogProd, dataProdIN);
            const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            FileSaver.saveAs(blob, `LAPORAN-PEMASUKAN-PRODUKSI-${moment(dateSelection).format('YYYYMMDD')}.xlsx`);
        } catch(error){
            console.log(error);
        }
    }


    const handleButtonDownloadXLSProdOUT = async (event) => {
        event.preventDefault();
        const sheetName     = "Laporan Pengeluaran Produksi";
        try{
            const buffer = await generateExcel(sheetName, columnLogProd, dataProdOUT);
            const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            FileSaver.saveAs(blob, `LAPORAN-PENGELUARAN-PRODUKSI-${moment(dateSelection).format('YYYYMMDD')}.xlsx`);
        } catch(error){
            console.log(error);
        }
    }

    const handleBtnEditDocIN = async(event) => {
        event.preventDefault();
        const DocID = encodeURIComponent(dataLogHeaderIN.DocumentID);
        navigate(`/app/trx-in-revise?docno=${DocID}`);
    }

    const handleBtnEditDocOUT = async(event) => {
        event.preventDefault();
        const DocID = encodeURIComponent(dataLogHeaderOUT.DocumentID).trim();
        navigate(`/app/trx-out-revise?docno=${DocID}`);
    }

    const handleFileUpload = (event) => {
        const file = event.target.files[0];
        const reader = new FileReader();
        reader.onload = async(e) => {
          const binaryStr   = e.target.result;
          const workbook    = XLSX.read(binaryStr, { type: 'binary' });
          const sheetName   = workbook.SheetNames[0];
          const sheet       = workbook.Sheets[sheetName];
          const sheetData   = XLSX.utils.sheet_to_json(sheet);
          setXLSUploadIN(sheetData);
        };
    
        reader.readAsBinaryString(file);
    };
    
    const filterNPostXLSIn = async (event) => {
        event.preventDefault();
        const filterCriteria1 = xlsUploadIN.filter(
            item => item.DocumentType === 'PENERIMAAN BAHAN BAKU & PEMBANTU' || 
            item.DocumentType === 'PENERIMAAN BAHAN DARI SUB KONTRAK' ||
            item.DocumentType === 'PENERIMAAN SISA PRODUKSI'
        );
        const filterCriteria2 = xlsUploadIN.filter(
            item => item.DocumentType === 'PENERIMAAN SAMPLE FINISH GOOD' ||
            item.DocumentType === 'PENERIMAAN FINISH GOOD HASIL PRODUKSI' ||
            item.DocumentType === 'PENERIMAAN FINISH GOOD DARI SUB KONTRAK'
        );
        const filterCriteria3 = xlsUploadIN.filter(item => item.DocumentType === 'PENERIMAAN MESIN/ EQUIPMENT/ SPAREPART');
        const filterCriteria4 = xlsUploadIN.filter(item => item.DocumentType === 'PENERIMAAN SCRAP/ REJECT');
        try {
          if(filterCriteria1[0].DocumentType===null){
            alert('Excel Upload tidak sesuai format!');
          } else {
            await axios.post("/api/inbound/mat/detail/new", filterCriteria1);
          }
          await axios.post("/api/inbound/fg/detail/new", filterCriteria2);
          await axios.post("/api/inbound/eq/detail/new", filterCriteria3);
          await axios.post("/api/inbound/was/detail/new", filterCriteria4);
          alert('Data posted successfully');
        } catch (error) {
          console.error('Error posting data:', error);
          alert('Failed to post data');
        }
    };
      
      useEffect(() => {
        const fetchDataMaster = async () => {
            try {
                const getTransactionIN    = await axios.get(`/api/data/transaction/all/in`);
                const getTransactionOUT   = await axios.get(`/api/data/transaction/all/out`);
                setOptInType(getTransactionIN.data.data);
                setOptOutType(getTransactionOUT.data.data);
            } catch (error) {
                console.error('Error fetching data:', error);
                }
            };
        fetchDataMaster();
    }, []);
    
    return(
        <div className="content-wrapper">
            <div className="content">
                <div className="container-fluid px-2">
                    <Row>
                        <Col className="pt-2 pt-lg-2">
                            <ul className="nav nav-tabs">
                                <li className="nav-item">
                                    <button className={`nav-link ${activeTab === 'tab1' ? 'active font-weight-bold' : ''}`} onClick={() => handleTabClick('tab1')} style={{fontSize: 'x-small'}}>Log Pemasukan</button>
                                </li>
                                <li className="nav-item">
                                    <button className={`nav-link ${activeTab === 'tab2' ? 'active font-weight-bold' : ''}`} onClick={() => handleTabClick('tab2')} style={{fontSize: 'x-small'}}>Log Pengeluaran</button>
                                </li>
                                <li className="nav-item">
                                    <button className={`nav-link ${activeTab === 'tab3' ? 'active font-weight-bold' : ''}`} onClick={() => handleTabClick('tab3')} style={{fontSize: 'x-small'}}>Log Pemasukan Produksi</button>
                                </li>
                                <li className="nav-item">
                                    <button className={`nav-link ${activeTab === 'tab4' ? 'active font-weight-bold' : ''}`} onClick={() => handleTabClick('tab4')} style={{fontSize: 'x-small'}}>Log Pengeluaran Produksi</button>
                                </li>
                            </ul>
                            <div className="tab-content">
                                <div className={`tab-pane ${activeTab === 'tab1' ? 'active bg-white' : ''}`}>
                                    <Row><Col><br/><h5> &nbsp;Log Data Pemasukan</h5></Col></Row>
                                    <Row>
                                        <Col sm={12} md={12} lg={12}  className="pt-lg-0">
                                            <Form>
                                                <Row>
                                                    <Col sm={12} md={4} lg={4} className="form-group">
                                                        <label htmlFor="srcStartDate" className="form-label" style={{fontSize:"xx-small"}}>Pilih Tanggal: &nbsp;</label>
                                                        <CompDateRangeWeek
                                                            state={dateSelection}
                                                            handleCallback={handleChangeDateIN}
                                                            style={{width: "100%"}}
                                                        /> 
                                                    </Col>
                                                    <Col sm={12} md={4} lg={4} className="form-group dropdown">
                                                        <br/>
                                                        <Link to={"#"} className="btn btn-primary active bg-primary dropdown-toggle" id="navbarDropdown2" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                            <small><i className="fas fa-plus"></i>&nbsp;</small>
                                                        </Link>
                                                        <div className="dropdown-menu" aria-labelledby="navbarDropdown2">
                                                            <Link className="dropdown-item" to="/app/trx-in?doctype=mat"><small>Pemasukan Material</small></Link>
                                                            <Link className="dropdown-item" to="/app/trx-in?doctype=eq"><small>Pemasukan Mesin & Peralatan</small></Link>
                                                            <Link className="dropdown-item" to="/app/trx-in?doctype=was"><small>Pemasukan Scrap & Excess</small></Link>
                                                            <Link className="dropdown-item" to="/app/trx-in?doctype=fg"><small>Pemasukan Barang Jadi</small></Link>
                                                            <Link className="dropdown-item" to="/app/trx-in-prod"><small>Pemasukan Produksi (BHP)</small></Link>
                                                            {/*<Link className="dropdown-item" to="/app/trx-in-subwh"><small>Pemasukan Gudang Luar Area</small></Link>*/}
                                                        </div>&nbsp;
                                                        <button className="btn btn-small btn-success swalDefaultAccess" onClick={handleButtonDownloadXLSPemasukan} ><i className="right fas fa-download"></i></button>
                                                    </Col>
                                                </Row>
                                            </Form>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <div className="ag-theme-quartz text-center" style={{ height: "80vh", width: "100%", fontSize: "x-small" }} >
                                                <AgGridReact 
                                                    columnDefs={colDefLogMaterialInbound} 
                                                    rowData={dataLogIN || []} 
                                                    defaultColDef={defaultColDef} 
                                                    pagination={'true'}
                                                    onRowDoubleClicked={handleRowLogINClick}>
                                                </AgGridReact>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                                <div className={`tab-pane ${activeTab === 'tab2' ? 'active bg-white' : ''}`}>
                                    <Row><Col><br/><h5> &nbsp;Log Data Pengeluaran</h5></Col></Row>
                                    <Row>
                                        <Col sm={12} md={12} lg={12}  className="pt-lg-0">
                                            <Form>
                                                <Row>
                                                    <Col sm={12} md={4} lg={4} className="form-group">
                                                        <label htmlFor="srcStartDate" className="form-label" style={{fontSize:"xx-small"}}>Pilih Tanggal: &nbsp;</label>
                                                        <CompDateRangeWeek
                                                            state={dateSelection}
                                                            handleCallback={handleChangeDateOUT}
                                                            style={{width: "100%"}}
                                                        /> 
                                                    </Col>
                                                    <Col sm={12} md={4} lg={4} className="form-group dropdown">
                                                        <br/>
                                                        <Link to={"#"} className="btn btn-primary active bg-primary dropdown-toggle" id="navbarDropdown3" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                            <small><i className="fas fa-plus"></i>&nbsp;</small>
                                                        </Link>
                                                        <div className="dropdown-menu" aria-labelledby="navbarDropdown3">
                                                            <Link className="dropdown-item" to="/app/trx-out?doctype=mat"><small>Pengeluaran Material</small></Link>
                                                            <Link className="dropdown-item" to="/app/trx-out?doctype=eq"><small>Pengeluaran Mesin & Peralatan</small></Link>
                                                            <Link className="dropdown-item" to="/app/trx-out?doctype=was"><small>Pengeluaran Scrap & Excess</small></Link>
                                                            <Link className="dropdown-item" to="/app/trx-out?doctype=fg"><small>Pengeluaran Barang Jadi</small></Link>
                                                            <Link className="dropdown-item" to="/app/trx-out-prod"><small>Pengeluaran Produksi (LPH)</small></Link>
                                                            {/*<Link className="dropdown-item" to="/app/trx-out-subwh"><small>Pengeluaran Gudang Luar Area</small></Link>*/}
                                                        </div>&nbsp;
                                                        <button className="btn btn-small btn-success swalDefaultAccess" onClick={handleButtonDownloadXLSPengeluaran} ><i className="right fas fa-download"></i></button>
                                                    </Col>
                                                </Row>
                                            </Form>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <div className="ag-theme-quartz text-center" style={{ height: "80vh", width: "100%", fontSize: "x-small" }} >
                                                <AgGridReact 
                                                    columnDefs={colDefLogMaterialOutbound} 
                                                    rowData={dataLogOUT || []} 
                                                    defaultColDef={defaultColDef} 
                                                    pagination={'true'}
                                                    onRowDoubleClicked={handleRowLogOUTClick}>
                                                </AgGridReact>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                                <div className={`tab-pane ${activeTab === 'tab3' ? 'active bg-white' : ''}`}>
                                    <Row><Col><br/><h5> &nbsp;Log Data Pemasukan Produksi</h5></Col></Row>
                                    <Row>
                                        <Col sm={12} md={12} lg={12}  className="pt-lg-0">
                                            <Form>
                                                <Row>
                                                    <Col sm={12} md={4} lg={4} className="form-group">
                                                        <label htmlFor="srcStartDate" className="form-label" style={{fontSize:"xx-small"}}>Pilih Tanggal: &nbsp;</label>
                                                        <CompDateRangeWeek
                                                            state={dateSelection}
                                                            handleCallback={handleChangeDateProdIN}
                                                            style={{width: "100%"}}
                                                        /> 
                                                    </Col>
                                                    <Col sm={12} md={8} lg={8} className="form-group dropdown">
                                                        <br/>
                                                        <button className="btn btn-small btn-success swalDefaultAccess" onClick={handleButtonDownloadXLSProdIN} ><i className="right fas fa-download"></i></button>
                                                    </Col>
                                                </Row>
                                            </Form>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <div className="ag-theme-quartz text-center" style={{ height: "80vh", width: "100%", fontSize: "x-small" }} >
                                                <AgGridReact 
                                                    columnDefs={colDefLogProdIN} 
                                                    rowData={dataProdIN || []} 
                                                    defaultColDef={defaultColDef} 
                                                    pinnedBottomRowData={pinBtmRowData}
                                                    pagination={'true'}
                                                    >
                                                </AgGridReact>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                                <div className={`tab-pane ${activeTab === 'tab4' ? 'active bg-white' : ''}`}>
                                    <Row><Col><br/><h5> &nbsp;Log Data Pengeluaran Produksi</h5></Col></Row>
                                    <Row>
                                        <Col sm={12} md={12} lg={12}  className="pt-lg-0">
                                            <Form>
                                                <Row>
                                                    <Col sm={12} md={4} lg={4} className="form-group">
                                                        <label htmlFor="srcStartDate" className="form-label" style={{fontSize:"xx-small"}}>Pilih Tanggal: &nbsp;</label>
                                                        <CompDateRangeWeek
                                                            state={dateSelection}
                                                            handleCallback={handleChangeDateProdOUT}
                                                            style={{width: "100%"}}
                                                        /> 
                                                    </Col>
                                                    <Col sm={12} md={8} lg={8} className="form-group dropdown">
                                                        <br/>
                                                        <button className="btn btn-small btn-success swalDefaultAccess" onClick={handleButtonDownloadXLSProdOUT}  ><i className="right fas fa-download"></i></button>
                                                    </Col>
                                                </Row>
                                            </Form>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <div className="ag-theme-quartz text-center" style={{ height: "80vh", width: "100%", fontSize: "x-small" }} >
                                                <AgGridReact 
                                                    columnDefs={colDefLogProdIN} 
                                                    rowData={dataProdOUT || []} 
                                                    defaultColDef={defaultColDef} 
                                                    pagination={'true'}
                                                    >
                                                </AgGridReact>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </div>        
                        </Col>
                    </Row>
                </div>
            </div>
            <Modal show={showModalLogIN} onHide={handleCloseModal} size="xl">
                <Modal.Header closeButton>
                    <Modal.Title>Dokumen {dataLogHeaderIN.DocumentID} - {dataLogHeaderIN.CustomsDocType} - {dataLogHeaderIN.CustomsDocID} &nbsp; &nbsp;  <Button onClick={handleBtnEditDocIN} className="btn btn-warning font-xsmall" ><i className="right fas fa-edit"></i></Button>&nbsp; <Button className="btn btn-danger font-xsmall" onClick={handleDelDoc} ><i className="right fas fa-trash"></i></Button> </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Col>
                        <h6>Detail Dokumen</h6>
                        <Table className="table table-striped" style={{fontSize: "small"}}>
                            <tbody>
                                <tr>
                                    <th style={{width:'15%'}}>Jenis Pabean</th>
                                    <td style={{width:'15%'}}>: {dataLogHeaderIN.CustomsDocType}</td>
                                    <th style={{width:'15%'}}>Supplier / Vendor</th>
                                    <td style={{width:'25%'}}>: {dataLogHeaderIN.VendorName}</td>
                                    <th style={{width:'10%'}}>Create By</th>
                                    <td style={{width:'15%'}}>: {dataLogHeaderIN.CreateBy}</td>
                                </tr>
                                <tr>
                                    <th style={{width:'10%'}}>Nomor Pabean</th>
                                    <td style={{width:'15%'}}>: {dataLogHeaderIN.CustomsDocID}</td>
                                    <th style={{width:'15%'}}>Jenis Transaksi</th>
                                    <td style={{width:'25%'}}>: {dataLogHeaderIN.DocumentType}</td>
                                    <th style={{width:'10%'}}>Create Date</th>
                                    <td style={{width:'15%'}}>: {moment(dataLogHeaderIN.CreateDate).format("YYYY-MM-DD  hh:mm:ss")}</td>
                                </tr>
                                <tr>
                                    <th style={{width:'10%'}}>Tanggal Pabean</th>
                                    <td style={{width:'15%'}}>: {dataLogHeaderIN.CustomsDocDate}</td>
                                    <th style={{width:'15%'}}>Tanggal Dokumen</th>
                                    <td style={{width:'25%'}}>: {dataLogHeaderIN.DocumentDate}</td>
                                    <th style={{width:'10%'}}>Update By</th>
                                    <td style={{width:'15%'}}>: {dataLogHeaderIN.UpdateBy}</td>
                                </tr>
                                <tr>
                                    <th style={{width:'10%'}}>Asal Barang</th>
                                    <td style={{width:'15%'}}>: {dataLogHeaderIN.Origin}</td>
                                    <th style={{width:'15%'}}>Nomor Dokumen</th>
                                    <td style={{width:'25%'}}>: {dataLogHeaderIN.DocumentID}</td>
                                    <th style={{width:'10%'}}>Update Date</th>
                                    <td style={{width:'15%'}}>: {dataLogHeaderIN.UpdateDate===null ? '': moment(dataLogHeaderIN.UpdateDate).format("YYYY-MM-DD  hh:mm:ss")}</td>
                                </tr>
                                <tr>
                                    <th style={{width:'10%'}}>Surat Jalan</th>
                                    <td style={{width:'15%'}}>: {dataLogHeaderIN.PackingSlipID}</td>
                                    <th style={{width:'15%'}}>Gudang</th>
                                    <td style={{width:'25%'}}>: {dataLogHeaderIN.WHLocation}</td>
                                    <th style={{width:'10%'}}>Transport / Trucking</th>
                                    <td style={{width:'15%'}}>: {dataLogHeaderIN.TransportID}</td>
                                </tr>
                                <tr>
                                    <th style={{width:'10%'}}>Catatan / Remarks</th>
                                    <td colSpan={6}>: {dataLogHeaderIN.Remark}</td>
                                </tr>
                            </tbody>
                        </Table>
                    </Col>
                    <Col lg={12}>
                        <h6>Detail Material</h6>
                        <div className="ag-theme-quartz text-center" style={{ height: "40vh", width: "100%", fontSize: "x-small" }} >
                            <AgGridReact 
                                defaultColDef={defaultColDef} 
                                columnDefs={colItemInbound} 
                                rowData={dataLogDetailIN}
                                pinnedBottomRowData={pinBtmRowData}
                                enableCellTextSelection={true}
                            >
                            </AgGridReact>
                        </div>
                    </Col>
                </Modal.Body>
            </Modal> 
            <Modal show={showModalDetailOUT} onHide={handleCloseModal} size="xl">
                <Modal.Header closeButton>
                    <Modal.Title>Dokumen {dataLogHeaderOUT.DocumentID} - {dataLogHeaderOUT.CustomsDocType} - {dataLogHeaderOUT.CustomsDocID} &nbsp;<Button className="btn btn-warning font-xsmall" onClick={handleBtnEditDocOUT} ><i className="right fas fa-edit"></i></Button> <Button className="btn btn-danger font-xsmall" onClick={handleDelDoc} ><i className="right fas fa-trash"></i></Button></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Col>
                        <h6>Detail Dokumen</h6>
                        <Table className="table table-striped" style={{fontSize: "small"}}>
                            <tbody>
                                <tr>
                                    <th style={{width:'10%'}}>Jenis Pabean</th>
                                    <td style={{width:'15%'}}>: {dataLogHeaderOUT.CustomsDocType}</td>
                                    <th style={{width:'15%'}}>Customer / Penerima</th>
                                    <td style={{width:'25%'}}>: {dataLogHeaderOUT.VendorName}</td>
                                    <th style={{width:'10%'}}>Create By</th>
                                    <td style={{width:'15%'}}>: {dataLogHeaderOUT.CreateBy}</td>
                                </tr>
                                <tr>
                                    <th style={{width:'10%'}}>Nomor Pabean</th>
                                    <td style={{width:'15%'}}>: {dataLogHeaderOUT.CustomsDocID}</td>
                                    <th style={{width:'15%'}}>Jenis Transaksi</th>
                                    <td style={{width:'25%'}}>: {dataLogHeaderOUT.DocumentType}</td>
                                    <th style={{width:'10%'}}>Create Date</th>
                                    <td style={{width:'15%'}}>: {moment(dataLogHeaderOUT.CreateDate).format("YYYY-MM-DD  hh:mm:ss")}</td>
                                </tr>
                                <tr>
                                    <th style={{width:'10%'}}>Tanggal Pabean</th>
                                    <td style={{width:'15%'}}>: {dataLogHeaderOUT.CustomsDocDate}</td>
                                    <th style={{width:'15%'}}>Tanggal Dokumen</th>
                                    <td style={{width:'25%'}}>: {dataLogHeaderOUT.DocumentDate}</td>
                                    <th style={{width:'10%'}}>Update By</th>
                                    <td style={{width:'15%'}}>: {dataLogHeaderOUT.UpdateBy}</td>
                                </tr>
                                <tr>
                                    <th style={{width:'10%'}}>Asal Barang</th>
                                    <td style={{width:'15%'}}>: {dataLogHeaderOUT.Origin}</td>
                                    <th style={{width:'15%'}}>Nomor Dokumen</th>
                                    <td style={{width:'25%'}}>: {dataLogHeaderOUT.DocumentID}</td>
                                    <th style={{width:'10%'}}>Update Date</th>
                                    <td style={{width:'15%'}}>: {dataLogHeaderOUT.UpdateDate===null ? '': moment(dataLogHeaderOUT.UpdateDate).format("YYYY-MM-DD  hh:mm:ss")}</td>
                                </tr>
                                <tr>
                                    <th style={{width:'10%'}}>Surat Jalan</th>
                                    <td style={{width:'15%'}}>: {dataLogHeaderOUT.PackingSlipID}</td>
                                    <th style={{width:'15%'}}>Gudang</th>
                                    <td style={{width:'25%'}}>: {dataLogHeaderOUT.WHLocation}</td>
                                    <th style={{width:'10%'}}>Transport / Trucking</th>
                                    <td style={{width:'15%'}}>: {dataLogHeaderOUT.TransportID}</td>
                                </tr>
                                <tr>
                                    <th style={{width:'10%'}}>Catatan / Remarks</th>
                                    <td colSpan={6}>: {dataLogHeaderOUT.Remark}</td>
                                </tr>
                            </tbody>
                        </Table>
                    </Col>
                    <Col lg={12}>
                        <h6>Detail Material</h6>
                        <div className="ag-theme-quartz text-center" style={{ height: "40vh", width: "100%", fontSize: "x-small" }} >
                            <AgGridReact 
                                defaultColDef={defaultColDef} 
                                columnDefs={colItemOutbound} 
                                rowData={dataLogDetailOUT}
                                enableCellTextSelection={true}
                            >
                            </AgGridReact>
                        </div>
                    </Col>
                </Modal.Body>
            </Modal>
            <Modal show={showModalDelete} onHide={handleCloseModalDelete} size="l" style={{top: '25%'}}>
                <Modal.Body className=" border border-danger rounded">
                    <Col className="text-center">
                        <h6>Apakah Anda yakin akan menghapus Dokumen {dataLogDetailIN[0].DocumentID} {dataLogDetailOUT[0].DocumentID}?</h6><br/>
                        <Button variant="danger" onClick={clickDelDoc} style={{width: '100px'}}>Ya</Button> &nbsp; &nbsp; 
                        <Button variant="secondary" onClick={handleCloseModalDelete} style={{width: '100px'}}>Tidak</Button>
                    </Col>
                </Modal.Body>
            </Modal>
            <Modal show={showModalDelSuccess} onHide={handleCloseModalDelete} size="l" style={{top: '25%'}}>
                <Modal.Body className=" border border-danger rounded">
                    <Col className="text-center">
                        <h6>Dokumen Berhasil Dihapus!</h6><br/>
                    </Col>
                </Modal.Body>
            </Modal>
        </div>
    )
}