import React from "react";
import { Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";

function MainDashboard(){
    return(
        <div>
            <div className="content-wrapper">
                <div className="content-header">
                    <div className="container-fluid">
                        <Row className="mb-2">
                            <Col><h1 className="m-0">Selamat Datang di Portal IT Inventory PT Sumber Bintang Rejeki </h1></Col>
                            <br/><br/>
                        </Row>
                    </div>
                </div>
                <div className="content">
                    <div className='container-fluid'>
                        <div className="row">
                            <div className="col-lg-4 col-md-4 col-sm-12">
                                <div className="card mb-3 shadow-hover" style={{maxWidth: '540px'}}>
                                    <Link to="/app/report/pemasukan" className="nav-link active">    
                                    <div className="row g-0">
                                        <div className="col-md-4 d-flex align-items-center justify-content-center border-2 bg-primary me-auto align-items-center">
                                            <h1 className="text-center"><i className={ `right fas fa-download`}></i></h1>
                                        </div>
                                        <div className="col-md-8">
                                            <div className="card-body">
                                                <h5 className="card-title">Laporan Pemasukan</h5>
                                                <p className="card-text"><small>Laporan Pemasukan Barang berdasarkan Dokumen Pabean</small></p>
                                            </div>
                                        </div>
                                    </div>
                                    </Link>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-12">
                                <div className="card mb-3 shadow-hover" style={{maxWidth: '540px'}}>
                                    <Link to="/app/report/pengeluaran" className="nav-link active">    
                                    <div className="row g-0">
                                        <div className="col-md-4 d-flex align-items-center justify-content-center border-2 bg-primary me-auto align-items-center">
                                            <h1 className="text-center"><i className={ `right fas fa-upload`}></i></h1>
                                        </div>
                                        <div className="col-md-8">
                                            <div className="card-body">
                                                <h5 className="card-title">Laporan Pengeluaran</h5>
                                                <p className="card-text"><small>Laporan Pengeluaran Barang berdasarkan Dokumen Pabean</small></p>
                                            </div>
                                        </div>
                                    </div>
                                    </Link>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-12">
                                <div className="card mb-3 shadow-hover" style={{maxWidth: '540px'}}>
                                    <Link to="/app/report/posisi-wip" className="nav-link active">    
                                    <div className="row g-0">
                                        <div className="col-md-4 d-flex align-items-center justify-content-center border-2 bg-primary me-auto align-items-center">
                                            <h1 className="text-center"><i className={ `right fas fa-th-list`}></i></h1>
                                        </div>
                                        <div className="col-md-8">
                                            <div className="card-body">
                                                <h5 className="card-title">Laporan Posisi WIP</h5>
                                                <p className="card-text"><small>Laporan Posisi Barang Dalam Proses <i>(Work In Progress)</i></small></p>
                                            </div>
                                        </div>
                                    </div>
                                    </Link>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-12">
                                <div className="card mb-3 shadow-hover" style={{maxWidth: '540px'}}>
                                    <Link to="/app/report/mutasi/bahan-baku" className="nav-link active">    
                                    <div className="row g-0">
                                        <div className="col-md-4 d-flex align-items-center justify-content-center border-2 bg-primary me-auto align-items-center">
                                            <h1 className="text-center"><i className={ `right fas fa-scroll`}></i></h1>
                                        </div>
                                        <div className="col-md-8">
                                            <div className="card-body">
                                                <h5 className="card-title">Laporan Mutasi Bahan Baku</h5>
                                                <p className="card-text"><small>Laporan Mutasi Pemasukan & Pengeluaran Bahan Baku</small></p>
                                            </div>
                                        </div>
                                    </div>
                                    </Link>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-12">
                                <div className="card mb-3 shadow-hover" style={{maxWidth: '540px'}}>
                                    <Link to="/app/report/mutasi/barang-jadi" className="nav-link active">    
                                    <div className="row g-0">
                                        <div className="col-md-4 d-flex align-items-center justify-content-center border-2 bg-primary me-auto align-items-center">
                                            <h1 className="text-center"><i className={ `right fas fa-layer-group`}></i></h1>
                                        </div>
                                        <div className="col-md-8">
                                            <div className="card-body">
                                                <h5 className="card-title">Laporan Mutasi Barang Jadi</h5>
                                                <p className="card-text"><small>Laporan Mutasi Pemasukan & Pengeluaran Barang Jadi</small></p>
                                            </div>
                                        </div>
                                    </div>
                                    </Link>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-12">
                                <div className="card mb-3 shadow-hover" style={{maxWidth: '540px'}}>
                                    <Link to="/app/report/mutasi/sisa-scrap" className="nav-link active">    
                                    <div className="row g-0">
                                        <div className="col-md-4 d-flex align-items-center justify-content-center border-2 bg-primary me-auto align-items-center">
                                            <h1 className="text-center"><i className={ `right fas fa-cube`}></i></h1>
                                        </div>
                                        <div className="col-md-8">
                                            <div className="card-body">
                                                <h5 className="card-title">Laporan Mutasi Sisa & Scrap</h5>
                                                <p className="card-text"><small>Laporan Mutasi Pemasukan & Pengeluaran Sisa & Scrap</small></p>
                                            </div>
                                        </div>
                                    </div>
                                    </Link>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-12">
                                <div className="card mb-3 shadow-hover" style={{maxWidth: '540px'}}>
                                    <Link to="/app/report/mutasi/mesin-peralatan" className="nav-link active">    
                                    <div className="row g-0">
                                        <div className="col-md-4 d-flex align-items-center justify-content-center border-2 bg-primary me-auto align-items-center">
                                            <h1 className="text-center"><i className={ `right fas fa-tools`}></i></h1>
                                        </div>
                                        <div className="col-md-8">
                                            <div className="card-body">
                                                <h5 className="card-title">Laporan Mutasi Mesin & Peralatan</h5>
                                                <p className="card-text"><small>Laporan Mutasi Pemasukan & Pengeluaran Mesin & Peralatan</small></p>
                                            </div>
                                        </div>
                                    </div>
                                    </Link>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-12">
                                <div className="card mb-3 shadow-hover" style={{maxWidth: '540px'}}>
                                    <Link to="/app/report/sub-warehouse" className="nav-link active">    
                                    <div className="row g-0">
                                        <div className="col-md-4 d-flex align-items-center justify-content-center border-2 bg-primary me-auto align-items-center">
                                            <h1 className="text-center"><i className={ `right fas fa-warehouse`}></i></h1>
                                        </div>
                                        <div className="col-md-8">
                                            <div className="card-body">
                                                <h5 className="card-title">Laporan Mutasi Gudang Luar Area</h5>
                                                <p className="card-text"><small>Laporan Mutasi Pemasukan & Pengeluaran Gudang Luar Area</small></p>
                                            </div>
                                        </div>
                                    </div>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MainDashboard;