import moment from "moment";
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';
import 'moment/locale/id';



const CompDateRangeWeek = ({ state, handleCallback }) => {
  moment.locale("id");

  const { start, end }  = state;
  const label           =`${start.format("DD MMMM YYYY")} - ${end.format("DD MMMM YYYY")}`;
  
  
  return (
    <DateRangePicker
      initialSettings={{
        startDate: start.toDate(),
        endDate: end.toDate(),
        ranges: {
          "Hari ini": [moment().toDate(), moment().toDate()],
          "Kemarin": [
            moment().subtract(1, "days").toDate(),
            moment().toDate(),
          ],
          "7 Hari terakhir": [
            moment().subtract(6, "days").toDate(),
            moment().toDate(),
          ],
          "30 Hari Terakhir": [
            moment().subtract(29, "days").toDate(),
            moment().toDate(),
          ],
          "Bulan ini": [
            moment().startOf("month").toDate(),
            moment().endOf("month").toDate(),
          ],
          "Bulan Sebelumnya": [
            moment().subtract(1, "month").startOf("month").toDate(),
            moment().subtract(1, "month").endOf("month").toDate(),
          ],
        },
      }}
      onCallback={handleCallback}
    >
      <div
        id="datePickerWeek"
        className="col-lg-12 col-md-12 col-sm-10 rounded"
        style={{
          background: "#fff",
          cursor: "pointer",
          fontSize: "small",
          padding: "5px 10px",
          border: "1px solid #ccc",
          width: "100%",
        }}
      >
        <i className="fa fa-calendar"></i>&nbsp;
        <span>{label}</span> <i className="fa fa-caret-down"></i>
      </div>
    </DateRangePicker>
  );
};

export default CompDateRangeWeek;
