import React, { Suspense } from 'react';
import { Route, Routes} from 'react-router-dom';
import MainLogin from './components/MainLogin.js';
import ProtectedRouter from './auth/ProtectedRouter.js';
import MainLayout from './components/MainLayout.js';
import MainDashboard from './components/MainDashboard.js';
import { ReportPemasukan } from './components/Report/ReportPemasukan.js';
import { ReportPengeluaran } from './components/Report/ReportPengeluaran.js';
import { ReportMutasi } from './components/page/ReportMutasi.js';
import { ReportWIP } from './components/Report/ReportWIP.js';
import { ReportMutBB } from './components/Report/ReportMutasiBB.js';
import { ReportMutFG } from './components/Report/ReportMutasiFG.js';
import { ReportMutWAS } from './components/Report/ReportMutasiWAS.js';
import { ReportMutMesin } from './components/Report/ReportMutasiMesin.js';
import { ReportTraceBB } from './components/Report/ReportTraceBB.js';
import { MainSetting } from './components/page/MainSetting.js';
import { PageReview } from './components/page/PageReview.js';
import { TransactionIn } from './components/page/TransactionIn.js';
import { TransactionOut } from './components/page/TransactionOut.js';
import { EditTrxIn } from './components/page/EditTrxIn.js';
import { EditTrxOut } from './components/page/EditTrxOut.js';
import { TrxInProd } from './components/page/TrxInProd.js';
import { TrxOutProd } from './components/page/TrxOutProd.js';
import { ReportSubWarehouse } from './components/Report/ReportSubWarehouse.js';

function App() {
  return (
      <Routes>
        <Route path="/" exact element={<MainLogin />} />
        <Route element={
          <ProtectedRouter>
            <Suspense fallback={<MainLogin />}>
              <MainLayout />
            </Suspense>
          </ProtectedRouter>}>
          <Route path="/app/home" exact element={<MainDashboard />} />
          <Route path="/app/report/pemasukan" exact element={<ReportPemasukan />} />
          <Route path="/app/report/pengeluaran" exact element={<ReportPengeluaran />} />
          <Route path="/app/report/posisi-wip" exact element={<ReportWIP />} />
          <Route path="/app/report/mutasi/:muttype" exact element={<ReportMutasi />} />
          <Route path="/app/report/mutasi/bahan-baku" exact element={<ReportMutBB />} />
          <Route path="/app/report/mutasi/barang-jadi" exact element={<ReportMutFG />} />
          <Route path="/app/report/mutasi/sisa-scrap" exact element={<ReportMutWAS />} />
          <Route path="/app/report/mutasi/mesin-peralatan" exact element={<ReportMutMesin />} />
          <Route path="/app/report/sub-warehouse" exact element={<ReportSubWarehouse />} />
          <Route path="/app/report/traceability/bahan-baku" exact element={<ReportTraceBB />} />
          <Route path="/app/review" exact element={<PageReview />} />
          <Route path="/app/trx-in" exact element={<TransactionIn />} />
          <Route path="/app/trx-in-prod" exact element={<TrxInProd/>} />
          <Route path="/app/trx-out-prod" exact element={<TrxOutProd/>} />
          <Route path="/app/trx-out" exact element={<TransactionOut />} />
          <Route path="/app/trx-in-revise" exact element={<EditTrxIn />} />
          <Route path="/app/trx-out-revise" exact element={<EditTrxOut />} />
          <Route path="/app/settings" exact element={<MainSetting />} />
          <Route path="*" element={<MainDashboard />} />
        </Route>
      </Routes>
  );
}

export default App;
